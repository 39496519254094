<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }" >
			<v-btn v-if="back" @click="previous" icon :disabled="isFirst"
				><v-icon v-on="on" >mdi-arrow-left-drop-circle</v-icon></v-btn
			>
			<v-btn v-else @click="next" icon :disabled="isLast"
				><v-icon  v-on="on" >mdi-arrow-right-drop-circle</v-icon></v-btn
			>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Renders a pair of buttons to move the current value up and down in a given array
// @group Buttons
export default {
	name: "DirectionButton",
	props: {
		// whether the back button should be active
		back: { type: Boolean },
		// whether the forward button should be active
		forward: { type: Boolean, default: true },
		// the array which the value exists in
		array: { type: Array },
		// the current value
		value: { type: [String, Number] },
	},
	data: () => {
		return {

			allowTooltip: false,
		}
	},
	computed: {
		currentPosition() {
			return this.array.indexOf(this.value);
		},
		isFirst() {
			return this.currentPosition == 0;
		},
		isLast() {
			return this.currentPosition == this.array.length - 1;
		},
		tooltip() {
			if (this.back) {
				return this.$t("buttons.back");
			}
			return this.$t("buttons.next");
		},
	},
	methods: {
		previous() {
			// moves the value down in the array
			var prev = this.array[this.currentPosition - 1];
			// fired when the previous button is clicked
			// @arg - the new value
			this.$emit("click", prev);
		},
		next() {
			// moves the value up in the array
			var next = this.array[this.currentPosition + 1];
			// fired when the next button is clicked
			// @arg - the new value

			this.$emit("click", next);
		},
	},
};
//
</script>
"
