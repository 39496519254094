<template>
	<v-dialog v-model="dialog" content-class="mw-small-dialog">
		<template v-slot:activator="{ on }">
			<v-btn x-small icon v-on="on" class="ml-2">
				<v-icon>mdi-delete</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title>{{ $t("misc.are_you_sure") }}</v-card-title>
			<v-card-text>
				{{ $t("config.goals.confirm_delete") }}
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="dialog = false">{{ $t("buttons.cancel") }}</v-btn>
				<v-btn color="primary" @click="deleteGoal">{{
					$t("buttons.delete")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Pop up dialog which deletes a goal from a users configuration 
// @group Config
export default {
	name: "DeleteGoal",
	props: {
		// the ID of the goal of the goal document
		goal: { type: String },
		// the ID of the sectionPart document
		part: { type: String },
		// The ID of the department 
		department: { type: String },
	},
	data: () => {
		return {
            next: false,
			dialog: false,
		};
	},
	
	methods: {
        deleteGoal() {
			var self = this;
			var next = {...this.$store.getters.next};
			var current = this.$store.state.current;
			var goal = {
                part: this.part,
				goal: this.goal,
				department: this.department,
			}

			self.$store
				.dispatch("organisation/removeGoalFromConfig", goal)
				.then(() => {
                    self.dialog = false;
					if( this.mwsurveyutilities.matchedGoal(current, goal ) ){
						if (next) {
							self.$store.commit("GO_TO", next);
						} else {
							self.$router.replace("/assessment/overview");
						}
					}
				});
		},
	},
    created(){
    },
};
//
</script>
"
