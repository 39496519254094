<template>
	<v-card>
		<v-card-title></v-card-title>
		<v-card-text>
			<v-combobox
				ref="combobox"
				@keydown.delete="clear"
				@keydown="checkNew"
				v-model="newGoal"
				:items="items"
				:label="$t('goals.nav.new')"
				:hint="$t('config.goals.combobox_hint')"
				permanent-hint
			></v-combobox>
			<v-textarea
				v-if="isNew"
				v-model="description"
				:label="$t('goals.fields.description')"
			>
			</v-textarea>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				id="new-goal-button"
				@click="saveNewGoal"
				:disabled="!newGoal"
				>{{ $t("buttons.save") }}</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
@import "./../../assets/css/variables.less";

#new-goal-button {
	color: white !important;
	background: @primary !important;
}
</style>

<script type="text/javascript">
// Adds a dialog to add new goal to the assessment configuration
// @group Misc
export default {
	name: "NewGoal",
	props: {
		exclude: { default: () => [] },
		hidden: { default: () => [] },
	},
	data: () => {
		return {
			description: "",
			newGoal: "",
		};
	},
	computed: {
		items() {
			var self = this;
			var goals = self.$store.getters["goals/active"];
			var exclude = self.exclude.filter((a) => self.hidden.indexOf(a) < 0);
			goals = goals.filter((g) => {
				return exclude.indexOf(g.id) < 0;
			});
			return goals.map((goal) => ({ value: goal.id, text: goal.name }));
		},
		isNew() {
			return this.newGoal.length > 0 && typeof this.newGoal == "string";
		},
	},
	methods: {
		checkNew() {
			if (this.newGoal && !this.isNew) {
				var textValue = this.$refs.combobox.lazySearch;
				var selected = this.newGoal.text;
				if (textValue !== selected) {
					this.newGoal = textValue;
				}
			}
		},
		clear() {
			this.newGoal = "";
			this.description = "";
		},
		saveNewGoal() {
			const self = this;
			if (self.isNew) {
				var data = {
					name: self.newGoal,
					status: "pending",
					author: self.$store.getters["auth/id"],
					description: self.description,
				};
				self.$store.dispatch("goals/insert", data).then((res) => {
					if (res) {
						self.$emit("new", res);
					}
					self.clear();
				});
			} else {
				if (self.newGoal.value) {
					self.$emit("new", self.newGoal.value);
					self.clear();
				}
			}
		},
	},

	created() {},
};
//
</script>
"
