<template>
	<v-chip dark x-small color="green" v-if="havePolls">
		<v-icon x-small class="mr-2">mdi-check-circle</v-icon
		>{{ $t("polls.have_responses") }}
	</v-chip>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Renders a chip in the navigation if there are any submitted polls for the current goal or part
// NB why are polls retrieved like this instead of by the response data id?
// Polls can be created before the response is created 
// @group Polls
export default {
	name: "PollChip",
	props: {
		// the id of the department the polls are for
		department: { type: String, required: true  },
		// the id of the goal
		goal: { type: String },
		// the id of goalgroup the poll is for 
		part: { type: String },
	},
	computed: {
		polls() {
			return this.$store.getters["collaborations/allSubmittedPolls"];
		},
		havePolls() {
			var d = this.department;
			var polls = this.polls;
			polls = polls.filter((p) => p.department == d);
			if (this.part) {
				var gg = this.part;
				polls = polls.filter((p) => p.part == gg);
			}

			if (this.goal) {
				var g = this.goal;
				polls = polls.filter((p) => p.goal == g);
			}

			return polls.length > 0;
		},
	},
};
//
</script>
"
