<template>
	<v-dialog v-model="dialog" content-class="mw-small-dialog">
		<template v-slot:activator="{ on }">
			<v-btn
				v-if="button == 'icon'"
				:color="disabled ? 'grey' : 'green'"
				icon
				v-on="on"
			>
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
			<v-btn v-else text color="igblack" v-on="on">{{
				$t("buttons.complete")
			}}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span v-if="!disabled">
					{{ $t("misc.are_you_sure") }}
				</span>
			</v-card-title>
			<v-card-text>
				<p v-if="disabled">
					{{ $t("survey.complete_all_questions_message") }}
				</p>
				<p v-else>
					{{ $t("collaboration.are_you_sure_return") }}
				</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn rounded @click="dialog = false" text>{{
					$t("buttons.cancel")
				}}</v-btn>
				<v-btn rounded @click="save" :disabled="disabled" color="primary">{{
					$t("buttons.confirm")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Displays a return button to allow delegate respondents to return the section to the lead respondent
// @group Delegation
export default {
	name: "Return",
	props: {
		// the id of the department currently in view
		id: { type: String },
		// whether the button should be disabled
		disabled: { type: Boolean, default: false },
		// what sort of button to render,  options: ["block", "icon"]
		button: { type: String, default: "block" },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		response() {
			return this.$store.getters.responseMap[this.id];
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		section_count() {
			return this.$store.getters["departments/canEditOrder"].length;
		},
	},
	methods: {
		save() {
			var self = this;
			self.$store
				.dispatch("collaborations/return", self.response.id)
				.then(() => {
					self.dialog = false;
					// if( self.section_count == 0 ){
					self.$router.replace("/results");
					// }
				});
		},
	},
};
//
</script>
"
