<template>
	<v-list-item
		dense
		@click="goToPart(department, part, id)"
		class="ig-goal-navigation"
		:class="{ active: isCurrent }"
	>
		<v-list-item-content>
			<v-list-item-title >{{ goal.name }}</v-list-item-title>
			<v-list-item-subtitle>
				<v-chip x-small v-if="goal.status == 'pending'" color="grey">{{
					$t("nav.custom_goal")
				}}</v-chip>
				<poll-chip
					class="ml-2"
					:department="department"
					:goal="id"
					:part="part"
				></poll-chip>
				<delete-goal
					v-if="showDelete"
					:goal="id"
					:part="part"
					:department="department"
				></delete-goal>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-icon>
			<v-icon small :color="completionColor">
				{{ icon }}
			</v-icon>
		</v-list-item-icon>
	</v-list-item>
</template>

<style lang="less">
.ig-goal-navigation {
	padding-left: 52px !important;
	&.active {
		background: white;
	}
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
import PollChip from "@c/survey/poll/PollChip";
import DeleteGoal from "@c/config/DeleteGoal";
// Displays the goal level navigation
// @group Navigation
export default {
	name: "GoalNavigation",
	props: {
		// the ID of the department
		department: { type: String },
		// the id of the section part
		part: { type: String },
		// the id of the goal document
		id: { type: String },
	},
	components: {
		PollChip,
		DeleteGoal,
	},
	computed: {
		...Vuex.mapState({
			goals: (state) => state.goals.data,
		}),
		user() {
			return this.$store.getters["auth/id"];
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		goal() {
			return this.goals[this.id] || {};
		},
		sectionPart() {
			return this.$store.state.sectionParts.data[this.part];
		},
		view() {
			return {
				part: this.part,
				goal: this.id,
				department: this.department,
			};
		},
		isCurrent() {
			return this.mwsurveyutilities.matchedGoal(
				this.$store.state.current,
				this.view
			);
		},		
	
		goalProgress() {
			return this.$store.getters.goalProgress(this.view)
		},
		completion() {
			if (this.goalProgress) {
				return this.goalProgress.rate;
			} else {
				return 0;
			}
		},
		completionColor() {
			return this.completion == 1 ? "primary" : "default";
		},
		custom() {
			return !this.sectionPart.goals.includes(this.id);
		},
		icon() {
			if (!this.completion) {
				return "mdi-checkbox-blank-outline";
			}
			if (this.completion == 1) {
				return "mdi-checkbox-marked";
			}
			return "mdi-minus-box";
		},
		showDelete() {
			if (!this.custom) {
				return false;
			}
			if (this.isLead) {
				return true;
			}
			// if (!this.goal.author || this.isLead) {
			// 	return true;
			// }
			// if (this.goal.author && this.goal.author == this.user) {
			// 	return true;
			// }
			return false;
		},
		mouseover() {
			return this.goal.mouseover
				? this.goal.mouseover
				: this.goal.description || false;
		},
	},

	methods: {
		// sets the view
		// dispatched when the goal is clicked
		goToPart() {
			this.$store.commit("GO_TO", {
				goal: this.id,
				part: this.part,
				department: this.department,
			});
		},
	},
};
//
</script>
" /
