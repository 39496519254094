import mwutilities from "@a/utilities.js";

export default {
	firestorePath: "departments",
	firestoreRefType: "collection",
	moduleName: "departments",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var section = doc.section;
			if (section) {
				dispatch("sections/fetchById", section);
			}
			return updateStore(doc);
		},
	},
	getters: {
		defaultOrder(state) {
			var ids = Object.fromEntries(
				Object.values(state.data).map((department) => {
					return [department.id, { name: department.name }];
				})
			);
			return mwutilities.getPositions(ids, "name");
		},
		defaultOrderConfigOnly(_, getters, __, rootGetters) {
			var order = getters.defaultOrder;
			var config = rootGetters["organisation/config"];
			return order.filter( o => config[o] )
		},
		canReadOrder(_, getters, __, rootGetters){
			var order = getters.defaultOrderConfigOnly;
			var canRead = rootGetters["sectionResponses/canRead"] || []
			return order.filter( a => canRead[a] )
		},
		canEditOrder(_, getters, __, rootGetters){
			var order = getters.defaultOrderConfigOnly;
			var canEdit = rootGetters["sectionResponses/canEdit"] || []
			return order.filter( a => canEdit[a] )
		},
		onlyMineOrder(_, getters, rootState){
			let org = rootState.organisation.data;
			let departments = getters.defaultOrder;
			if( org.departments ){
				departments = departments.filter ( o => org.departments.includes(o));
			}
			return departments;
		}

	},
	mutations: {},
	actions: {},
};
