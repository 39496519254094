<template>
	<v-list-group
		class="ig-part-navigation"
		color="igblack"
		v-model="active"
		:class="{ 'active-section': isCurrent }"
		dense
		no-action
		sub-group
	>
		<template v-slot:activator>
			<v-list-item-title class="subtitle-2">
				{{ goalGroup.name }}
				<completion-rate
					:part="id"
					:department="department"
					:goal="false"
				></completion-rate>
				<poll-chip
					class="ml-2"
					:department="department"
					:part="id"
				></poll-chip>
			</v-list-item-title>
		</template>
		<template v-slot:prependIcon>
			<v-avatar
				size="25"
				:color="isCurrent ? 'primary' : 'secondary'"
				:class="{ 'white--text': isCurrent }"
				class="ml-2"
				>{{ index + 1 }}</v-avatar
			>
		</template>
		<!-- <template v-slot:appendIcon>
			<v-icon></v-icon>
		</template>
		<v-tooltip right >
			<template v-slot:activator="{ on }">
				<v-btn
					icon
					v-on="on"
					@click="dialog = true"
					class="floating-button"
				>
					<v-icon color="green">mdi-plus-circle</v-icon>
				</v-btn>
			</template>
			{{ $t("nav.add_new_goal") }}
		</v-tooltip>
		<v-dialog v-model="dialog" content-class="mw-medium-dialog">
			<new-goal :exclude="excludeGoals" @new="handleNewGoal"></new-goal>
		</v-dialog> -->
		<goal-navigation
			v-for="goal in filteredGoalIds"
			:key="goal"
			:id="goal"
			:part="id"
			:department="department"
		></goal-navigation>
	</v-list-group>
</template>

<style lang="less">
.floating-button {
	position: absolute;
	right: 15px;
	top: 10px;
}
</style>
<script type="text/javascript">
import Vuex from "vuex";
// import NewGoal from "@c/config/NewGoal";
import GoalNavigation from "@c/navigation/assessment-navigation/GoalNavigation";
import CompletionRate from "@c/navigation/assessment-navigation/CompletionRate";
import PollChip from "@c/survey/poll/PollChip"

// Displays the navigation for a goal group
// @group Navigation
export default {
	name: "PartNavigation",
	props: {
		// defines the active part in the parent menu
		activeParent: { type: [String, Boolean] },
		// the id of this sectionPart document
		id: { type: String },
		// the id of the parent department
		department: { type: String },
		// the position of this part in the department order
		index: { type: Number },
	},
	data: () => {
		return {
			dialog: false,
			active: false,
		};
	},
	components: {
		// NewGoal,
		CompletionRate,
		GoalNavigation,
		PollChip,
	},
	computed: {
		...Vuex.mapState({
			current: (state) => state.current.part,
			goal: (state) => state.current.goal,
		}),
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		sectionPart() {
			return this.$store.state.sectionParts.data[this.id];
		},
		goalGroup() {
			return this.$store.state.goalGroups.data[this.sectionPart.goalGroup];
		},
		isCurrent() {
			return this.current == this.id;
		},
		viewOrder() {
			return this.$store.getters.canEditView;
		},
		goalIds() {
			return this.$store.getters.config[this.department][this.id];
		},
		goals(){
			return this.$store.state.goals.data;
		},
		filter(){
			return this.$store.state.tag_filter;
		},
		filteredGoalIds(){
			const self = this;
			if( !self.filter.length ){
				return self.goalIds;
			}
			return self.goalIds;
		},
		excludeGoals() {
			var id = this.department;
			return this.viewOrder
				.filter((p) => p.department == id)
				.map((p) => p.goal);
		},
	},
	methods: {
		handleNewGoal(goal) {
			const self = this;
			var department = self.department;
			var part = self.id;
			this.$store
				.dispatch("organisation/addGoalToConfig", {
					department,
					part,
					goal,
				})
				.then(() => {
					self.dialog = false;
				});
		},
	},
	watch: {
		active(value) {
			if( value ){
				// emits when a goal group is expanded
				this.$emit("opened", this.id )
			}
		},
		activeParent(value)  {
			if (value !== this.id) {
				this.active = false;
			}
		},
		isCurrent: {
			immediate: true,
			handler(value) {
				if (value) {
					this.active = true;
				}
			},
		},
		goal() {
			if (this.active == false && this.current == this.id) {
				this.active = true;
			}
		},
	},
	// created(){

	// }
};
//
</script>
"
