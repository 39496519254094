<template>
	<div>
		<v-app-bar dense flat color="transparent">
			<inngo-logo @toggle="toggle"></inngo-logo>
		</v-app-bar>
		<v-sheet class="ig-sidebar-inner" color="transparent">
			<v-component v-if="showNavigation" :is="showNavigation"></v-component>
		</v-sheet>
	</div>
</template>

<script type="text/javascript">
import Vuex from "vuex";
import Login from "@c/auth/Login";
import AssessmentSideBar from "@c/navigation/assessment-navigation/AssessmentSidebar";
import InngoLogo from "@c/navigation/InngoLogo/";
import ConfigNavigation from "@c/navigation/ConfigNavigation/";

// selects the correct sidebar content based on the meta properties defined in the route
// and renders it in the sidebar
// @group Navigation
export default {
	name: "SidebarNavigation",
	components: {
		AssessmentSideBar,
		ConfigNavigation,
		InngoLogo,
		Login,
	},
	computed: {
		...Vuex.mapState({
			user: (state) => state.auth.data,
			auth: (state) => state.auth,
		}),
		showNavigation() {
			if (!this.auth.logged_in) {
				return "Login";
			}
			if (this.$route.meta.in == "assessment") {
				return "AssessmentSideBar";
			}
			if (this.$route.meta.in == "config") {
				return "ConfigNavigation";
			}
			return false;
		},
	},
	methods: {
		toggle() {
			// fired when the button to expand and collapse the sidebar is clicked
			this.$emit("toggle");
		},
	},
};
</script>
