export default {
    loading: false,
    current: {
        department: false,
        part: false, 
        goal: false
    },
	tag_filter: [],
    colors: [
		"green",
		"red", 
		"blue",
		"yellow", 
		"orange",
		"purple",
		"teal" 
	] 

}