import Vue from "vue";
import App from "./App.vue";

// @doc: main > imports
// this is used for interacting with firebase e.g. for authentication
import firebase from "firebase";

// @doc: main > imports
// this determines all the paths in our application
import router from "./router";

// @doc: main > imports
// @doc: this manages reading and writing to the database
import store from "./store";

// @doc: main > imports
// this lib provides the bones of most of the UI style
import vuetify from "./plugins/vuetify";

// @doc: main > imports
// this manages displaying dates in a readable way
import VueMoment from "vue-moment";
import moment from "moment-timezone";

import VueGtag from "vue-gtag";

// @doc: main > imports
// these files give us some reusable functions / settings for use across the application and are
// available using the this.mwutilities.functionName (etc) in components
import mwutilities from "@a/utilities.js";
import mwsurveyutilities from "@a/surveyutilities.js";
Vue.prototype.mwutilities = mwutilities;
Vue.prototype.mwsurveyutilities = mwsurveyutilities;

// @doc: main > imports
// these files are used for internationalisation - i.e. german translations!
// see translations documentation
import VueI18n from "vue-i18n";
import english from "@a/translations/english.js";
import german from "@a/translations/german.js";

// @doc: main > imports
// this tells us how long a user has been inactive for e.g. to log them out
import IdleVue from "idle-vue";
Vue.config.productionTip = false;

// // @doc: main > imports
// // this is a tool which monitors for errors in the browser - it lets me track down when things go wrong

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	Vue,
	dsn:
		"https://8712f3b12cf743ed893eb91c382f2614@o397452.ingest.sentry.io/5667087",
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: [
				"localhost",
				"app.inngo.de",
				"inngo-dev-public.web.app",
				/^\//,
			],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

Vue.use(VueMoment, {
	moment,
});
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: "de",
	messages: {
		en: english,
		de: german,
	},
});
var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
	measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENTID,
};
Vue.use(
	VueGtag,
	{
		config: { id: firebaseConfig.measurementId },
	},
	router
);

firebase.initializeApp(firebaseConfig);

// @doc: main > check authentication
// listens for authentication changes
firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

// @doc: main > check authentication
// when a user is logged in it retrieves their organisations and profile info
firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store
			.dispatch("auth/start")
			.then(() => {})
			.catch(() => {
				router.replace("/error");
			});
	}
});

Vue.use(IdleVue, {
	store,
	idleTime: 1200000,
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
