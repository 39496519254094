// import mwutitlities from "@a/mwutitlities.js";
export default {
	firestorePath: "sections",
	firestoreRefType: "collection",
	moduleName: "sections",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, id, { dispatch }) {
			dispatch("sectionParts/fetchBySection", id);
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {},
};
