export default {
	firestorePath: "goals",
	firestoreRefType: "collection",
	moduleName: "goals",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [["status", "in", ["published", "pending"]]],
	},
	getters: {
		active(state, _, __, rootGetters) {
			return Object.values(state.data).filter((a) => {
				return (a.status == "published" || a.author == rootGetters["auth/id"]);
			});
		},
	},
	mutations: {},
	actions: {
		fetchAll({ dispatch }) {
			dispatch(`fetchAndAdd`, {clauses: {limit: 0, where: [["status", "in", ["published", "pending"]]]} });
		},

    },
};
