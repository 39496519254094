import mwutilities from "@a/utilities.js"

export default  {
    firestorePath: 'questions',
    firestoreRefType: 'collection', 
    moduleName: 'questions',
    statePropName: 'data',
    namespaced: true, 
    sync: {
        where: [
            ["status", "==", "published"]
        ]
    },
    getters: {
        defaultOrder(state){
            var order = mwutilities.getPositions(state.data);
            return order.map((o) => state.data[o]);
        },
        counted(state, getters){
            state;
            let order = getters.defaultOrder;
            order = order.filter( a => a.type !== "mwComment" ); 
            return order;
        }
    },
    mutations: {

    },
    actions: {

    },
}
  
