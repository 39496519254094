import mwutilities from "@a/utilities.js";
import mwsurveyutilities from "@a/surveyutilities.js";
import surveyutilities from "../assets/surveyutilities";
export default {
	currentView(state) {
		if (
			!state.current.department ||
			!state.current.part ||
			!state.current.goal
		) {
			return false;
		}
		return true;
	},
	viewOrder(state, getters) {
		var obj = [];
		var config = getters["organisation/config"];
		var order = getters["departments/defaultOrderConfigOnly"];
		var partOrder = getters.partOrder;
		order.forEach((department) => {
			partOrder[department].forEach((part) => {
				config[department][part].forEach((goal) => {
					if( state.goals.data[goal] ){
						obj.push({ department, part, goal });
					}
				});
			});
		});
		return obj;
	},
	canEditView(_, getters){
		return getters.viewOrder.filter( v => getters["sectionResponses/canEdit"][v.department] )
	},	
	canReadView(_, getters){
		return getters.viewOrder.filter( v => getters["sectionResponses/canRead"][v.department]  )
	},
	first(_, getters) {
		return getters.canEditView[0];
	},
	previous(state, getters) {
		var all = getters.canEditView;
		var index = mwutilities.matchedIndex(state.current, all);
		return all[index - 1] || false;
	},
	next(state, getters) {
		var all = getters.canEditView;
		var index = mwutilities.matchedIndex(state.current, all);
		return all[index + 1] || false;
	},
	last(state, getters) {
		var all = getters.canEditView;
		return all[all.length - 1] || false;
	},
	defaultPartOrder(state, getters) {
		if (!state.sections.data) {
			return;
		}
		var order = getters["goalGroups/defaultOrder"];
		return Object.fromEntries(
			Object.values(state.sections.data).map((section) => {
				var parts = Object.keys(state.sectionParts.data).filter(
					(p) => state.sectionParts.data[p].section == section.id
				);
				var partOrder = parts.sort((a, b) => {
					var x = order.indexOf(state.sectionParts.data[a].goalGroup);
					var y = order.indexOf(state.sectionParts.data[b].goalGroup);
					return x < y ? -1 : x > y ? 1 : 0;
				});
				return [section.id, partOrder];
			})
		);
	},
	partOrder( state, getters ){
		let partOrder = {...getters.defaultPartOrder};
		let config = getters["organisation/config"];
		return Object.fromEntries(  Object.keys(config)
			.filter( id => state.departments.data[id] )
			.map( id => {
				let department = state.departments.data[id];
				let withGoals = [];
				let departmentPartOrder = partOrder[department.section];
				if( departmentPartOrder ){
					withGoals = departmentPartOrder.filter( p => config[id][p] ); 
				} 
				return [ id, withGoals ]
		})  )
	},
	defaultConfig(state, getters) {
		var departments = state.departments.data;
		return Object.fromEntries(
			Object.keys(departments).map((department) => {
				var section = departments[department].section;
				var partOrder = getters.defaultPartOrder[section];
				if (!partOrder) {
					return [];
				}
				return [
					department,
					Object.fromEntries(
						partOrder.map((part) => [part, state.sectionParts.data[part].goals]).filter( a => a[1] )
					),
				];
			})
		);
	},
	config(state, getters){
		var config = {...getters["organisation/config"]};
		Object.keys(config).forEach( department =>  {
			if( !getters.responseMap[department] ){
				delete config[department];
				return;
			}
			Object.keys( config[department] ).forEach( part => {
				if( !state.sectionParts.data[part] ){
					delete config[department][part];
					return;
				}
				config[department][part] = config[department][part].filter( g => state.goals.data[g] );
				if( config[department][part].length == 0 ){
					delete config[department][part];
				}
			})
		})
		return config;
	},
	currentSection(state) {
		return state.departments.data[state.current.department]
			? state.departments.data[state.current.department].section
			: false;
	},
	currentGoalGroup(state) {
		return state.sectionParts.data[state.current.part]
			? state.sectionParts.data[state.current.part].goalGroup
			: false;
	},
	responseMap(state, getters) {
		var object = {};
		var departments = getters["departments/defaultOrder"];
		var sectionResponses = Object.values(state.sectionResponses.data);
		departments.forEach((department) => {
			var found = sectionResponses.find(
				(response) => response.department == department
			);
			if (found) {
				found.department = department;
				object[department] = found;
			}
		});
		return object;
	},
	currentResponse(state, getters) {
		if (!state.current.department) {
			return false;
		}

		return getters.responseMap[state.current.department];
	},
	currentResponseData(state, getters) {
		if (
			!state.current.department ||
			!state.current.part ||
			!state.current.goal
		) {
			return false;
		}
		var map = getters["responseData/map"];
		var dataId = mwutilities.getByString(
			map,
			`${state.current.department}.${state.current.part}.${state.current.goal}`
		);
		if (dataId) {
			return state.responseData.data[dataId];
		}
		return false;
	},
	currentlyDisabled(__, getters) {
		if (!getters.currentResponse) {
			return false;
		}
		return ["delegated", "complete", "deleted"].includes(
			getters.currentResponse.status
		);
	},
	currentStatus(__, getters) {
		if (!getters.currentResponse) {
			return false;
		}
		return getters.currentResponse.status;
	},
	rates(state, getters) {
		var items = [...getters.viewOrder];
		return items.map((a) => {
			var dataPoint = mwutilities.getByString(
				getters["responseData/map"],
				`${a.department}.${a.part}.${a.goal}`
			);
			if (!dataPoint) {
				return { ...a, rate: 0 };
			} else {
				var response = state.responseData.data[dataPoint];
				var data = response.data;
				var questions = getters["questions/counted"];
				var visibleQuestions = questions.filter((a) =>
					mwsurveyutilities.visibilityTest(a, data)
				);
				var answered = [...visibleQuestions].filter((a) => data[a.id]);
				return { ...a, rate: answered.length / visibleQuestions.length };
			}
		});
	},
	goalProgress(_, getters ){
		return (view) => {
			let rates = getters.rates;
			return rates.find((a) => {
				return surveyutilities.matchedGoal(a, view);
			});
		}
	}
};
