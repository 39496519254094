import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";

/* @doc: router > meta properties: 
    in: "config" || "assessment" // used for defining which navigation should appear in the LH sidebar
    lead: true || false, // defines pages which require that the user is a lead respondent
    auth: true || false // defines pages that require the user to be logged in 
    sidebar: "expanded" || "collapsed" || false // specifies how the LH sidebar should be rendered - false means no sidebar at all 
    dark: true || false // defines whether the page is dark blue or white 
*/ 

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		meta: {
			dark: true,
			sidebar: "expanded"
		},
		component: () =>
			import(/* webpackChunkName: "error" */ "@v/Home.vue"),
	},
	{
		path: "/home",
		name: "Dashboard",
		meta: {
			auth: true,
			dark: true,
			sidebar: false
		},
		component: () =>
			import(/* webpackChunkName: "error" */ "@v/Dashboard.vue"),
	},
	{
		path: '/poll/:id',
		name: 'Poll',
		props: true,
		meta: {
			sidebar: false
		},
		component: () => import('@c/survey/poll/Render.vue')
	},  
	{
		path: '/ref/:code',
		name: 'Ref',
		props: true,
		meta: {
			sidebar: "expanded"
		},
		component: () => import('@v/Referral.vue')
	},  
	{
		path: '/organisation-profile',
		name: 'OrganisationProfile',
		meta: {
			auth: true, 
			sidebar: "collapsed",
			lead: true
		},
		component: () => import('@v/OrganisationProfile.vue')
	},  
	{
		path: '/assessment-config/departments',
		name: 'DepartmentSelection',
		meta: {
			in: "config",
			lead: true,	
			auth: true, 
			sidebar: "expanded",
		},
		component: () => import(/* webpackChunkName: "login" */ '@c/config/DepartmentSelectionList.vue')
	},  
	{
		path: '/assessment-config/overview',
		name: 'ConfigOverview',
		meta: {
			in: "config",
			lead: true,	
			auth: true, 
			sidebar: "expanded",
		},
		component: () => import(/* webpackChunkName: "login" */ '@c/config/AssessmentConfig.vue')
	},  
	{
		path: '/assessment-config/sections',
		name: 'SectionConfig',
		meta: {
			in: "config",
			auth: true, 
			lead: true,
			sidebar: "expanded",
		},
		component: () => import(/* webpackChunkName: "login" */ '@c/config/SectionConfig.vue')
	},  
	{
		path: '/manage-collaborations',
		name: 'CollaborationDashboard',
		meta: {
			auth: true, 
			sidebar: false,
			lead: true
		},
		component: () => import('@v/CollaborationDashboard.vue')
	},  
	{
		path: '/assessment',
		name: 'Assessment',
		meta: {
			in: "assessment",
			auth: true, 
			sidebar: "expanded"
		},
		component: () => import('@v/Assessment.vue')
	}, 
	{
		path: '/results',
		name: 'Results',
		meta: {
			auth: true, 
			sidebar: "collapsed"
		},
		component: () => import('@v/Results.vue')
	}, 
	{
		path: '/analysis',
		name: 'Analysis',
		meta: {
			auth: true, 
		},
		component: () => import('@v/Analysis.vue')
	}, 
	{
		path: '/help',
		name: 'Help',
		props: true,
		meta: {
			auth: true, 
		},
		component: () => import('@v/Help.vue')
	}, 

];


const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});


// @doc: router > check auth 
// this uses the meta property "auth" to check if the user has permission to access the page before
// redirecting if not 
// @becca we have to check with firebase here (not vuex) as the data is not loaded into the 
// page before this runs
router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
