<template>
	<v-card flat class="pa-2 navigation-wrapper" color="transparent">	
		<template v-if="canAccessAssessment">
			<department-navigation v-if="current" :id="current"></department-navigation>
		</template>		
	</v-card>
</template>


<style lang="less">

</style>


<script type="text/javascript">

	// renders the list of sections to navigate through the assessment
	// @group navigation
	import Vuex from "vuex";
	import DepartmentNavigation from "@c/navigation/assessment-navigation/DepartmentNavigation";
	export default {
		name: "AssessmentNavigation", 
		components: {
			DepartmentNavigation
		},
		computed: {
			...Vuex.mapState({
				organisation: state => state.organisation.data, 
				assessment: state => state.assessment.data, 
				current: state => state.current.department
			}),
			order(){
				return this.$store.getters['departments/defaultOrderConfigOnly'];
			},
			canAccessAssessment(){
				return this.$store.getters['assessment/canAccessAssessment']
			}
		},

	}
// </script>"