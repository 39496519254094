<template>
	<v-app-bar
		class="ig-main-navigation"
		dense
		:color="dark ? 'primary' : 'white'"
		flat
		fixed
	>
		<inngo-logo @toggle="toggle"></inngo-logo>
		<v-spacer></v-spacer>
		<logged-in-top-navigation
			v-if="auth.logged_in"
		></logged-in-top-navigation>
	</v-app-bar>
</template>

<script type="text/javascript">
import Vuex from "vuex";
import InngoLogo from "@c/navigation/InngoLogo";
import LoggedInTopNavigation from "@c/navigation/top-navigation/LoggedInTopNavigation";

// renders the main navigation at the top of the page
// @group Navigation
export default {
	name: "TopNavigation",
	components: {
		InngoLogo,
		LoggedInTopNavigation,
	},
	computed: {
		...Vuex.mapState({
			auth: (state) => state.auth,
		}),
		dark() {
			return this.$route.meta.dark;
		},
	},
	methods: {
		toggle() {
			// fired when the button to collapse/expand the sidebar is clicked
			this.$emit("toggle");
		},
	},
};
//
</script>
"
