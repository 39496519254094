<template>
	<div class="ig-section-navigation">
		<v-list-item dense class="pa-0">
			<v-list-item-icon>
				<department-action-menu :id="id"></department-action-menu>
			</v-list-item-icon>
			<v-list-item-content>
				<div class="mb-4">
					<div class="department-name text-lg-subtitle-1 text-body-1">
						{{ department.name }}
						<status-chip v-if="isLead" :department="id"></status-chip>
					</div>
					<v-spacer></v-spacer>
					<div class="department-buttons">
						<direction-button
							back
							:array="order"
							:value="id"
							@click="(d) => move(-1)"
						></direction-button>
						<direction-button
							forward
							:array="order"
							:value="id"
							@click="(d) => move(1)"
						></direction-button>
					</div>
				</div>
			</v-list-item-content>
		</v-list-item>

		<large-new-goal-button></large-new-goal-button>
		<div>
			<completion-rate
				linear
				show-count
				:part="false"
				:department="id"
				:goal="false"
			></completion-rate>
		</div>
		<div class="mt-4">
			<part-navigation
				v-for="(part, i) in partOrder"
				:key="part"
				:id="part"
				:index="i"
				:department="id"
				@opened="activePart = part"
				:activeParent="activePart"
			></part-navigation>
		</div>
	</div>
</template>

<style lang="less" scoped>
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
	margin-right: 12px;
}

.department-buttons {
	width: 80px;
	position: absolute;
	right: 0;
	top: 10px;
}

.department-name {
	word-break: keep-all;
	width: calc(100% - 85px);
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
import DepartmentActionMenu from "@c/navigation/assessment-navigation/DepartmentActionMenu";
import DirectionButton from "@c/buttons/DirectionButton";
import CompletionRate from "@c/navigation/assessment-navigation/CompletionRate";
import PartNavigation from "@c/navigation/assessment-navigation/PartNavigation";
import StatusChip from "@c/navigation/assessment-navigation/StatusChip.vue";
import LargeNewGoalButton from "@c/navigation/assessment-navigation/LargeNewGoalButton";
// renders the department level navigation content including the navigational buttons and constituent parts
// @group Navigation
export default {
	name: "DepartmentNavigation",
	props: {
		// The ID of the department
		id: {
			type: String,
			required: true,
		},
	},
	data: () => {
		return {
			activePart: false,
		};
	},
	components: {
		DirectionButton,
		CompletionRate,
		PartNavigation,
		StatusChip,
		DepartmentActionMenu,
		LargeNewGoalButton,
	},
	computed: {
		...Vuex.mapState({
			departments: (state) => state.departments.data,
			current: (state) => state.current.department,
			sectionParts: (state) => state.sectionParts.data,
		}),
		department() {
			return this.departments[this.id];
		},
		order() {
			return this.$store.getters["departments/canEditOrder"];
		},
		viewOrder() {
			return this.$store.getters.viewOrder;
		},
		partOrder() {
			return this.$store.getters.partOrder[this.id];
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
	},
	methods: {
		// sets the current view in the store
		goTo(id) {
			var to = this.viewOrder.find((a) => a.department == id);
			this.$store.commit("GO_TO", to);
		},
		// moves the view up or down in the default order
		move(direction) {
			var self = this;
			var index = self.order.indexOf(self.id);
			index = index + direction;
			self.goTo(self.order[index]);
		},
	},
};
//
</script>
"
