<template>
	<v-list-item @click="logout">
		<v-list-item-icon>
			<v-icon>mdi-logout-variant</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>{{$t("buttons.logout")}}</v-list-item-title>
		</v-list-item-content>
		<automatic-logout></automatic-logout>
	</v-list-item>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import AutomaticLogout from "@c/auth/AutomaticLogout"
	// Renders a button which logs out the authenticated user
	// @group Auth
	export default {
		name: "Logout",
		methods: {
			// @vuese
			// Dispatches a logout event and redirects the user to a landing page
			logout(){
				const self = this;
				self.$store.dispatch("auth/stop").then( () => {
					if( self.$route.path !== "/" ){
						self.$router.replace("/")
					}
				})
			}
		}, 
		components: {
			AutomaticLogout
		}
	}
</script>"