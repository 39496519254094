Object.byString = function(o, s) {
	s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	s = s.replace(/^\./, '');           // strip a leading dot
	var a = s.split('.');
	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
}
export default {
	getByString(object, string ){
		try {
			return Object.byString(object, string )
		}
		catch {
			return false
		}
	},
	getFields(model) {
		return Object.values(model).filter( field => field.display )
	},
	getEmpty(model){
		var obj = {}
		Object.values(model).forEach( field => obj[field.value] = field.default )
		return obj;
	},
	matchedIndex(item, array){
		var match = array.find( a => {
			return JSON.stringify(a) === JSON.stringify(item);
		});
		var index = array.indexOf( match );
		return index;
	},
	getUniqueId(){
		var result	= '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < 16; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},
	prettyPrint: function(str, strCase ){
		if( !str ){ return "" }
		str = str.split("_").join(" ");
		str = str.split("-").join(" ");
		switch (strCase){
			case "TITLE":
				var sentence = str.toLowerCase().split(" ");
				for(var i = 0; i< sentence.length; i++){
					sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
				}
				return sentence.join(" ");
			case "SENTENCE":
				str = str.toLowerCase();
				str = str[0].toUpperCase() + str.slice(1);
				return str;
			case "LOWER":
				return str.toLowerCase();
			case "UPPER":
				return str.toUpperCase();
			default:
				return str;
		}
	},
	validateEmail(str){
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str)){
			return (true)
		}
		return false
	},
	strToKey: function(str){
		if( !str ){ return "" }
		str = str.split(" ").join("-");
		str = str.replace(/[^\w\s]/gi, '-');
		return str.toLowerCase();
	},
	sortByKey: function(array, key) {
		return array.sort(function(a, b) {
			var x = a[key]; var y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	},
	moveItemInArray(id, array, direction){
		let delta = direction == "up" ? -1 : 1;
		var index = array.indexOf(id);
		var newIndex = index + delta;
		if (newIndex < 0  || newIndex == array.length) return;
		var indexes = [index, newIndex].sort((a, b) => a - b); 
		array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); 		
	},
	getPositions(array, property = "position" ){
		var keys = Object.keys(array);
		keys.sort( ( a, b ) => {
			var x = array[a][property]; 
			var y = array[b][property]; 
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		})
		return keys;				

	},
	itemsArray(obj, text = "name" ){
		return Object.keys(obj).map( id => {
			return {
				value: id,
				text: obj[id][text] 
			}
		})
	},
	upperCaseFirst(str){
		return str.charAt(0).toUpperCase() + str.substr(1);
	},	
	lowerCaseFirst(str){
		return str.charAt(0).toLowerCase() + str.substr(1);
	}

}