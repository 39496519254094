<template>
	<v-card>
		<v-card-title>{{ $t("misc.switch_organisation") }}</v-card-title>
		<v-card-text>
			<v-checkbox
				v-for="(organisation, id) in organisations"
				:key="id"
				v-model="value"
				:value="id"
				:label="organisation.name"
			>
			</v-checkbox>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text @click="$emit('close')">{{ $t("buttons.cancel") }}</v-btn>
			<v-btn color="primary" @click="submit">{{
				$t("buttons.submit")
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
.unstyled-link {
	text-decoration: none;
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
// Displays a button so that users with more than one organisation can switch between organisations
// @group Misc
export default {
	name: "SwitchOrganisations",
	data: () => {
		return {
			value: false,
		};
	},
	computed: {
		...Vuex.mapState({
			organisations: (state) => state.organisations.data,
			organisation: (state) => state.organisation.data,
		}),
	},
	methods: {
		// @vuese
		// Changes the organisation in the store, "starts" the assessment
		// then redirects the user to the landing page
		submit() {
			const self = this;
			self.$emit("close");
			self.$store.dispatch("organisation/start", self.value).then(() => {
				self.$router.replace("/home");
			});
		},
	},
	watch: {
		organisation: {
			immediate: true,
			handler() {
				this.value = this.organisation.id;
			},
		},
	},
};
//
</script>
"
