export default {
	firestorePath: "sectionResponses",
	firestoreRefType: "collection",
	moduleName: "sectionResponses",
	statePropName: "data",
	namespaced: true,

	getters: {
		canRead(state, _, __, rootGetters) {
			var map;
			if (rootGetters["assessment/isLead"]) {
				map = Object.values(state.data).map((a) => {
					return [a.department, a.id];
				});
			} else {
				var id = rootGetters["auth/id"];
				map = Object.values(state.data)
					.filter((a) => a.users[id])
					.map((a) => {
						return [a.department, a.id];
					});
			}
			return Object.fromEntries(map);
		},
		canEdit(state, _, __, rootGetters) {
			var map;
			if (rootGetters["assessment/isLead"]) {
				map = Object.values(state.data).map((a) => {
					return [a.department, a.id];
				});
			} else {
				var id = rootGetters["auth/id"];
				map = Object.values(state.data)
					.filter((a) => a.users[id] == "delegate")
					.map((a) => {
						return [a.department, a.id];
					});
			}
			return Object.fromEntries(map);
		},
	},
	mutations: {},
	actions: {
		fetchRespondentResponses({ dispatch, rootGetters }) {
			var role = rootGetters["assessment/userRole"];
			if (role == "leadRespondent") {
				dispatch("fetchAll");
			}
			if (role == "delegate" || role == "dataUser") {
				dispatch("fetchSome");
			}
		},
		fetchAll({ dispatch, rootState }) {
			var assessment = rootState.assessment.data.id;
			if (!assessment) {
				return;
			}
			return dispatch("openDBChannel", {
				clauses: { where: [["assessment", "==", assessment]] },
			}).then( () => {
				dispatch("responseData/fetchByAssessment", assessment, {root: true });
			})
		},
		fetchSome({ dispatch, rootGetters, state}) {
			var assessment = rootGetters["assessment/id"];
			var user = rootGetters["auth/id"];
			if (!assessment || !user) {
				return;
			}
			return dispatch("openDBChannel", {
				clauses: {
					where: [
						["assessment", "==", assessment],
						[`users.${user}`, "in", ["dataUser", "delegate"]],
					],
				},
			}).then( () => {
				let ids = Object.keys(state.data);
				ids.forEach( id => {
					dispatch("responseData/fetchBySection", id, {root: true });
				})
			})
		},
		revoke({ dispatch }, id) {
			dispatch("set", { id, status: "revoked" });
		},
		return({ dispatch, state, rootGetters }, id) {
			dispatch("set", { id, status: "returned" }).then( () => {			
				var user = rootGetters["auth/id"];
				dispatch("removeUserAccess", {
					id: id,
					user: user,
				});
				return state.data[id].collaboration
			})
		},
		removeUserAccess({ dispatch }, { user, id }) {
			dispatch("patch", { id, [`users.${user}`]: "dataUser" });
		},
	},
};
