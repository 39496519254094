<template>
	<span>
		<v-menu v-if="isMobile">
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on" :color="color"
					><v-icon>mdi-dots-horizontal</v-icon></v-btn
				>
			</template>
			<v-list dense>
				<template v-for="(nav, name) in links">
					<v-list-item
						:key="name"
						v-if="nav.length == 1"
						:color="color"
						text
						router-link
						:to="nav[0].link"
					>
						<v-list-item-title> {{ nav[0].name }}</v-list-item-title>
					</v-list-item>
					<template v-else>
						<v-list-item
							v-for="(item, index) in nav"
							:key="`${name}-${index}`"
							router-link
							:to="item.link"
						>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</template>
				</template>
			</v-list>
		</v-menu>
		<template v-else>
			<span v-for="(nav, name) in links" :key="name">
				<v-btn
					v-if="nav.length == 1"
					:color="color"
					text
					router-link
					:to="nav[0].link"
				>
					{{ nav[0].name }}
				</v-btn>
				<v-menu v-else dense open-on-hover top>
					<template v-slot:activator="{ on }">
						<v-btn :color="color" text v-on="on">
							{{ name }} <v-icon small>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-list dense>
						<v-list-item
							v-for="(item, index) in nav"
							:key="index"
							router-link
							:to="item.link"
						>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</span>
		</template>
	</span>
</template>

<style lang="less">
.unstyled-link {
	text-decoration: none;
}
</style>

<script type="text/javascript">
// Takes a list of links and displays them either as a horizontal navigation
// or as a collapsed menu, if the screen width is mobile
// @group Navigation

export default {
	name: "ResponsiveNavigation",
	props: {
		links: {
			// A keyed object of either an
			// Object  { link: "", name: "" }
			// or Arrays thereof
			// Where an array is given, the content is rendered as a submenu
			type: Object,
			required: true,
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		dark() {
			return this.$route.meta.dark;
		},
		color() {
			return this.dark ? "white" : "ig-black";
		},
	},
};
//
</script>
"
