<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-chip
				v-on="on"
				v-if="!linear"
				x-small
				class="ml-1 pa-1 white--text"
				:color="color"
				>{{ content }}</v-chip
			>
			<v-progress-linear
				v-on="on"
				v-else
				:value="content"
				:color="color"
				background-color="white"
				height="15"
			>
				<v-chip x-small :style="labelStyle">{{ content }} </v-chip>
			</v-progress-linear>
		</template>

		<span>{{ $t("misc.progress") }}</span>
	</v-tooltip>
</template>

<style lang="less"></style>

<script type="text/javascript">
// renders the completion rate as either a bar or a badge for any defined view
// @group Navigation
export default {
	name: "CompletionRate",
	props: {
		// the goal for which the completion rate is to be calculated
		goal: { type: [String, Boolean], required: false },
		// the department for which the completion rate is to be calculated
		department: { type: [String, Boolean], required: false },
		// the part for which the completion rate is to be calculated
		part: { type: [String, Boolean], required: false },
		// whether to display the completion rate as a bar. Defaults to badge
		linear: { type: Boolean, default: false },
		// whether to display the completion rate as a % value - defaults to true
		percentage: { type: Boolean, default: true },
		// whether to label the completion rate
		label: { type: Boolean, default: false },
	},
	computed: {
		rates() {
			return this.$store.getters.rates;
		},
		rate() {
			var self = this;
			if (self.goal) {
				var value = self.rates.find((a) => {
					var b = {
						goal: self.goal,
						part: self.part,
						department: self.department,
					};
					return this.mwsurveyutilities.matchedGoal(a, b);
				});
				if (value) {
					return value.rate || 0;
				} else {
					return 0;
				}
			} else {
				var values = [...self.rates];
				if (self.department) {
					values = values.filter((a) => a.department == self.department);
				}
				if (self.part) {
					values = values.filter((a) => a.part == self.part);
				}
				if (self.percentage) {
					return this.mwsurveyutilities.averageCompletionRate(values) || 0;
				} else {
					return values.filter((a) => a.rate == 1).length;
				}
			}
		},
		dark() {
			return this.$route.meta.dark;
		},
		color() {
			if (this.dark) {
				return "green";
			}
			if (this.rate == 0) {
				return "grey";
			} else if (this.rate > 0.7) {
				return "green";
			} else {
				return "primary";
			}
		},
		content() {
			if (this.percentage) {
				return Math.floor(this.rate * 100) + "%";
			} else {
				return this.rate;
			}
		},
		labelStyle() {
			let pos = this.rate * 100;
			if (pos > 5 && pos < 50) {
				pos = pos - 5;
			}
			if (pos > 50 && pos < 90) {
				pos = pos - 8;
			}
			let style = { position: "absolute" };
			if (pos > 90) {
				style.right = 0;
			} else {
				style.left = `${pos}%`;
			}

			return style;
		},
	},
};
//
</script>
"
