export default {
    results: {
        bar_chart: "Bar chart",
        line_chart: "Line Chart",
        area_chart: "Area Chart",
        hbar_chart: "Horizonal Bar Chart",
        average: "Average",
        series: "Series",
        departments: "Departments",
        all_questions: "All Questions",
        goal_groups: "Goal Groups",
        your_score: "My Organisation",
        all_scores: "All Organisations",
    },
    polls: {
        intro:
        "Create individual links to share with colleagues. You will be able to see their answers later",
        overview: "Poll overview",
        launch: "Launch polls",
        button: "Poll",
        new: "New Poll",
        nickname: "Nickname",
        link: "Link",
        return_message:
            "Are you sure? After you have saved you will no longer be able to see or change your answers.",
        none_found: "No polls found",
        thank_you:
            "Thank you for participating in this poll. Your answers have now been shared with the lead respondent for your organisation.",
        have_responses: "Poll Responses",
    },
    survey: {
        complete_all_questions_message: "Please answer all questions.",
        noanswer: "No Answer",
    },
    navigation: {
        assessment_config: "Assessment config",
        analysis: "Analysis",
        start_assessment: "Start assessment",
        results: "Results",
        assessment: "Assessment",
        custom_goal: "New Goal",
        assessment_home: "Assessment Home",
        pending: "Pending",
        goal_pending: "Goal still pending",
    },
    buttons: {
        back: "Back",
        cancel: "Cancel",
        change: "Change",
        close: "Close",
        complete: "Complete",
        confirm: "Confirm",
        continue: "Continue",
        copy: "Copy",
        delete: "Delete",
        download: "Download",
        edit: "Edit",
        import: "Import",
        login: "Login",
        logout: "Log out",
        next: "Next",
        paste: "Paste",
        return: "Return",
        save: "Save",
        submit: "Submit",
    },
    collaboration: {
        are_you_sure_return: "Are you sure you want to return this section to your lead respondent. You will no longer be able to edit your answers?",
        access_denied: "Access denied",
        activated: "Collaboration activated",
        delegate: "Delegate",
        delegated: "Delegated",
        delegate_section: "Delegate Section ",
        delegation: "Delegation",
        last_login: "Last Login",
        manage: "Manage Collaborations",
        please_setup_an_account_message: "Please register to participate",
        revoked: "Collaboration has been revoked",
        status: "Collaboration status",
        statuses: {
            activated: "Active",
            published: "Inactive",
            revoked: "Revoked",
            returned: "Retured",
            delegated: "Delegated"
        },
    },
    misc: {
        status: "Status",
        complete: "Complete",
        logged_in_as: "Logged in as {name}",
        copied: "Copied",
        saved: "Saved",
        new_user_info: "Thank you for registering. Please check your email for further information.",
        confirm_deletion: "Are you sure you want to delete",
        general_error: "Correct errors on this page",
        are_you_sure: "Are you sure?",
        delegation_warning: "You can't edit a delegated section",
        completion_warning: "You can't edit a completed section",
        search: "Search",
        are_you_still_theere: "Sind Sie noch da?",
        logout_warning: "Sie sind seit 20 Minuten nicht mehr aktiv gewesen. Aus Sicherheitsgründen werden Sie in {{seconds}} Sekunden automatisch ausgeloggt."    },
    tags: {
        search: {
            add_filter: "Add Filter",
            clear_filters: "Clear Filters",
            search_by_tags: "Search by Tags",
            search_or_add: "Search tags or add your own",
            operator_and: "Every criteria must be met",
            operator_or: "Any criteria can be met",
        },
        nav: {
            single: "Tag",
            new: "New Tag",
            plural: "Tags",
        },
        fields: {
            add: "Add Tag",
            description: "Description",
            name: "Tag",
        },
        are_you_sure: "Are you sure you want to add this tag to the database?",
        tag_exists: "Tag already exists",
    },
    departments: {
        nav: {
            new: "New Department",
            plural: "Departments",
            single: "Department",
        },
        fields: {
            name: "Department name",
            type: "Department type",
        }

    },
    organisations: {
        nav: {
            plural: "Organisations",
            single: "Organisation",
            new: "New Organisation",
        },
        fields: {
            name: "Name",
            address: "Address",
            state: "State",
            city: "Stadt",
            postcode: "Post code",
            owner: "Owner",
            type: "Organisation Type",
            beds: "Number of beds",
            universityhospital: "Lehrkrankenhaus",
            edit_user_assingment: "User role",
            lead_respondent: "Lead respondent",
            data_user: "Data User",
            ik_nummer: "IK Nummer"
        },

    },
    questions: {
        config: "Question Config",
        navigation: {
            plural: "Questions",
            single: "Question",
            new: "New Question",
        },
        field_labels: {
            scored: "Question is scored",
            name: "Question name",
            name_warning: "The question name can not be edited later. It acts as the ID and cannot be seen by the participant.",
            type: "Question Type",
            title: "Question title",
            helptext: "Help text",
            mouseover: "Mouseover text",
            visible_if: "Visible If",
        },
        errors: {
            empty: "Every answer option must have a value",
            duplicate_value: "Values must be unique",
        },
        preview: "Vorschau",
        answer_options: {
            plural: "Answer options",
            add: "Add answer option",
            field_labels: {
                text: "Text",
                help: "The text is shown to the participant",
                value: "Value",
                value_help: "The value is a reference and is shown in the data export",
                score: "Score",
            }
        }
    },
    config: {
        goals: {
            new_warning: "Neu erstellte Ziele müssen von InnGo freigegeben werden, bevor sie der Konfiguration hinzugefügt werden können",
            select_all: "Select all",
            deselect_all: "Deselect all",
            add_selected: "Add selected",
            accept_empty: "Empty assessment",
            hide_goal: "Hide this goal everywhere",
            combobox_hint: "Select from the dropdown or add text",
        },
        nav: {
            overview: "Overview",
            department_setup: "Set up deparments",
            goal_config: "Goal configuration",
            goal_setup: "Goal set up ",
            goal_groups: "Goap groups",
            skip: "Skip all ",
        },
        can_not_reconfigure: "Can not reconfigure section",
        accept_settings: "Accept all settings",
        save: "Save",
    },
    users: {
        nav: {
            new: "New User",
            single: "User",
            plural: "User",
        },
        fields: {
            name: "Name",
            email: "Email Address",
            organsiations: "User Organisations",
        }
    },
    sections: {
        nav: {
            single: "Section",
            plural: "Sections",
            new: "New Section",
        },
        section_parts: {
            add_section: "Add section",
            add: "Add section part",
        },
        fields: {
            name: "Section name",
            save_layout: "Save New Layout",
        },
        goal_groups: {
            title: "Goal groups",
            count: "Goals in this group",
            set_up: "Goal set up ",
            add_more_goals: "Add More Ziele",
            add_goals: "Add goals",
        }
    },
    goals: {
        nav: {
            single:	"Goals",
            new: "New Goal",
        },
        fields: {
            name: "Goal name",
            mouseover: "Mouseover text",
        },
    },
    goal_groups: {
        nav: {
            new: "New Goal group",
            plural: "Goal groups",
        },
        fields: {
            name: "Goal group name",
        }
    }
}