export default  {
    firestorePath: 'tags',
    firestoreRefType: 'collection', 
    moduleName: 'tags',
    statePropName: 'data',
    namespaced: true, 
    sync: {
        where: [
            ["status", "in", ["published"] ]
        ],
        defaultValues: {
            status: "published"
        },
    },

    actions: {
        addAllToFilter({state, commit }){
            const v = Object.keys(state.data);
            commit("SET_FILTER", v, {root: true })
        }
    },
}
  
