<template>
    <v-card color="primary" dark flat @click="dialog = true" class="text-center rounded-lg pa-3 mb-5">
        <small><v-icon class="mr-8">mdi-plus</v-icon>Eigenes Ziel hinzufügen </small>

		<v-dialog v-model="dialog" content-class="mw-medium-dialog">
			<new-goal @new="handleNewGoal"></new-goal>
		</v-dialog>
    </v-card>
</template>


<script>
import NewGoal from "@c/config/NewGoal";
import Vuex from "vuex";
export default {
    name: "LargeNewGoalButton", 
    computed: {
		...Vuex.mapState({
			part: (state) => state.current.part,
			department: (state) => state.current.department,
		}),
    },
    data: () => {
        return {
            dialog: false,
        }
    },
    methods: {
		handleNewGoal(goal) {
			const self = this;
			self.$store
				.dispatch("organisation/addGoalToConfig", {
					department: self.department,
					part: self.part,
					goal,
				})
				.then(() => {
					self.dialog = false;
				});
		},
    }, 
    components: {
        NewGoal
    }
}
</script>