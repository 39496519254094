<template>
	<v-menu close-on-click close-on-content-click>
		<template v-slot:activator="{ on }">
			<v-btn icon v-on="on" class="pa-3"
				><v-icon>mdi-dots-horizontal-circle</v-icon></v-btn
			>
		</template>
		<v-list>
			<v-list-item dense v-if="isDelegate">
				<return :disabled="departmentCompletionRate < 1" :id="id"> </return>
			</v-list-item>
			<v-list-item dense v-if="isLead">
				<v-dialog
					v-model="dialog"
					content-class="mw-medium-dialog"
				>
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on">{{
							$t("collaboration.delegation")
						}}</v-btn>
					</template>
					<v-card>
						<v-card-title>
							{{ $t("collaboration.delegate") }}
						</v-card-title>
						<v-card-text>
							<delegate :id="id"></delegate>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="dialog = false">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-list-item>
			<v-list-item dense v-if="isLead">
				<complete :disabled="departmentCompletionRate < 1" :id="id">
				</complete>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Complete from "@c/survey/tools/Complete";
import Return from "@c/survey/tools/Return";
import Delegate from "@c/survey/tools/Delegate.vue";
// renders a submenu of actions for the section
// @group Navigation
export default {
	name: "DepartmentActionMenu",
	props: {
		// the ID of the department
		id: { type: String, required: true },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	components: {
		Complete,
		Return,
		Delegate,
	},
	computed: {
		department() {
			return this.$store.state.departments.data[this.id];
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		isDelegate() {
			return this.$store.getters["assessment/isDelegate"];
		},
		departmentCompletionRate() {
			var rates = this.$store.getters.rates;
			var department = this.id;
			var values = rates.filter((r) => r.department == department);
			return this.mwsurveyutilities.averageCompletionRate(values);
		},
	},
};

</script>
