export default {
	firestorePath: "collaborations/{id}",
	firestoreRefType: "doc",
	moduleName: "delegation",
	statePropName: "data",
	namespaced: true,
	sync: {
	},
	getters: {
	},
	mutations: {},
	actions: {
	},
};
