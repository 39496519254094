export default {
	firestorePath: "assessments/{id}",
	firestoreRefType: "doc",
	moduleName: "assessment",
	statePropName: "data",
	namespaced: true,
	getters: {
		id(state) {
			return state.data.id;
		},
		userRole(state, getters, rootState) {
			var user = rootState.auth.data.id;
			if (!state.data.users) {
				return false;
			}
			var role = state.data.users[user];
			return role;
		},
		isLead(_, getters) {
			return getters.userRole == "leadRespondent";
		},
		isDelegate(_, getters) {
			return getters.userRole == "delegate";
		},
		isDataUser(_, getters) {
			return getters.userRole == "dataUser";
		},
		canAccessAssessment(_, getters) {
			var role = getters.userRole;
			return role && ["delegate", "leadRespondent"].includes(role);
		},
		canAccessData(_, getters) {
			var role = getters.userRole;
			return role && ["dataUser", "delegate", "leadRespondent"].includes(role);
		},
	},
	mutations: {},
	actions: {},
};
