export default  {
    firestorePath: 'organisations',
    firestoreRefType: 'collection', 
    moduleName: 'organisations',
    statePropName: 'data',
    namespaced: true, 
    sync: {
        defaultValues: {
            status: "published"
        },
    },
    getters: {
        
    },
    mutations: {
    },
    actions: {
        fetchAll({dispatch, rootState}, organisations ){
            if( organisations ){
                dispatch("fetchByIds", organisations );
            }
            if( !rootState.organisation.data.id ){
                if( organisations ){
                    let id = organisations[0];
                    dispatch("organisation/start", id, {root: true });
                }
            }
        }
    },
}
  
