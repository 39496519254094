<template>
	<v-dialog v-model="dialog" content-class="mw-small-dialog">
		<template v-slot:activator="{ on }">
			<v-btn
				v-if="button == 'icon'"
				:color="disabled ? 'grey' : 'green'"
				icon
				v-on="on"
			>
				<v-icon>mdi-check-circle</v-icon>
			</v-btn>
			<v-btn v-else text :disabled="disabled" v-on="on">{{
				$t("buttons.complete")
			}}</v-btn>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<p v-if="disabled">
					{{ $t("survey.complete_all_questions_message") }}
				</p>
				<p v-else>{{ $t("misc.are_you_sure") }}</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="save" color="primary" :disabled="disabled">{{
					$t("buttons.complete")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less"></style>

<script type="text/javascript">
// renders a button which sets the provided section response to status complete
// @group Survey
export default {
	name: "Complete",
	props: {
		// the id of the department currently in view
		id: { type: String },
		// whether the button should be disabled
		disabled: { type: Boolean, default: false },
		// what sort of button to render,  options: ["block", "icon"] 
		button: { type: String, default: "block" },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	methods: {
		// @vuese
		// updates the relevant section response to status complete
		save() {
			var id = this.id;
			var response = this.$store.getters.responseMap[id];
			this.$store.dispatch("sectionResponses/set", {
				id: response.id,
				status: "complete",
			});
			this.dialog = false;
		},
	},
};
//
</script>
"
