<template>
	<v-dialog v-model="dialog" content-class="mw-small-dialog">
		<v-card>
			<v-card-title>{{ $t("misc.are_you_still_there") }}</v-card-title>
			<v-card-text> </v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="logout" color="ig-blue">
					{{ $t("buttons.logout") }}
				</v-btn>
				<v-btn @click="dialog = false" color="primary">
					{{ $t("buttons.cancel") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less"></style>

<script type="text/javascript">
// This uses a third party lib called idle-vue to detect when a user has been inactive for 20 minutes and automatically logs them out
// @group Auth
export default {
	name: "AutomaticLogOut",
	data: () => {
		return {
			seconds: 30,
			dialog: false,
		};
	},
	computed: {
		idle() {
			return this.isAppIdle;
		},
	},
	watch: {
		idle(idle) {
			if (idle) {
				this.dialog = true;
				this.countDown();
			} else {
				this.dialog = false;
				this.seconds = 30;
			}
		},
		seconds() {
			if (0 == this.seconds) {
				this.logout();
			}
		},
	},
	methods: {
        // @vuese
        // Dispatches a logout event and redirects the user to the landing page
        logout() {
			const self = this;
			self.$store.dispatch("auth/logout").then(() => {
				if (self.$route.path !== "/") {
					self.$router.replace("/");
				}
			});
		},
		// @vuese 
        // Counts down 30 seconds (between the inactive dialog appearing and logout triggered)
		countDown() {
			if (this.idle) {
				if (this.seconds > -1) {
					setTimeout(() => {
						this.seconds -= 1;
						this.countDown();
					}, 1000);
				}
			}
		},
	},
};
</script>
"
