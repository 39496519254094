<template>
	<v-chip dark x-small :color="color" v-if="text">
		{{ text }}
	</v-chip>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Displays a single chip with the status of the provided department
//@group Misc
export default {
	name: "StatusChip",
	props: {
		// the id of the department
		department: { type: String },
	},
	computed: {
		response() {
			return this.$store.getters.responseMap[this.department];
		},
		status() {
			return this.response ? this.response.status : "";
		},
		color() {
			var colors = {
				delegated: "primary",
				returned: "primary",
				complete: "complete",
			};
			return colors[this.status] || false;
		},
		text() {
			var texts = {
				delegated: this.$t("collaboration.delegated"),
				returned: this.$t("collaboration.statuses.returned"),
				complete: this.$t("misc.complete"),
			};
			return texts[this.status] || false;
		},
	},
};
//
</script>
"
