import Vue from "vue"

export default {
    GO_TO(state, view){
        for( var key in view ){
            Vue.set(state.current, key, view[key] )
        }
    }, 
    LOADING(state, value ){
        Vue.set(state, "loading", value)
    },
    SET_FILTER(state, value ){
        Vue.set(state, "tag_filter", value)
    },
    CLEAR_FILTER(state ){
        Vue.set(state, "tag_filter", [])
    },
}