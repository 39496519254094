export default {
	matchedGoal( a, b ){
		if( a.sectionResponse && b.sectionResponse ){
			return a.part == b.part &&
			a.goal == b.goal &&
			a.sectionResponse == b.sectionResponse 	
		}
		return a.part == b.part &&
		a.goal == b.goal &&
		a.department == b.department 
	},
	averageCompletionRate(values){
		var sum  = values.reduce(( sum, value ) => {
			return sum + (value.rate || 0);
		}, 0 )
		var count = values.length;
		return sum / count;
	},
	visibilityTest( question, data ){
		var condition = question.visibleIf;
		
		if( !condition  ){
			return true;
		}
		else{
			var visible = false;
			var value = data[condition.question]
			if( condition.operator == "in" ){
				visible = condition.value.indexOf( value ) > -1 
			}
			else{
				var test = `'${value}' ${condition.operator} '${condition.value}'`;
				visible = eval(test);  
			}
			return visible;
		}
	},
	calculateScore( questions, data ){
		// score forumula:
		// relevance x (implementaion + year )
		
		var relevance = "HCIB78ImyFic8U04iWPp";	
		var implementaion = "4Da1epraTLViVJBzEVqH";
		var horizon = "oOPubf2eWL86CmLwBCBc";
		
		var values = {
			[relevance]: 0,
			[implementaion]: 0,
			[horizon]: 0
		};

		// returns {questionId: {answer: score}}
		var scores = Object.fromEntries(questions.map( question => [question.id, Object.fromEntries( Object.keys(question.answerOptions).map( a => {
			let score = parseFloat(question.answerOptions[a].score);
			let key = question.answerOptions[a].value;
			return [key, score ] 
		}))]))	


		Object.keys(values).forEach( q => {
			if( data[q] && data[q] ){
				values[q] = scores[q][data[q]]
			}
		})

		var score = values[relevance] * (values[implementaion] + values[horizon]);
		if( isNaN(score) ){
			return null;
		}
		return parseFloat(this.handlungsbedarf(score));
	},
	handlungsbedarf(value) {
		if (!value) {
			return "0.0";
		}
		return ((1 / 260) * value * 5).toFixed(1);
	},
	getValue(id, response, scores ){
		var value = response.data[id];
		if(!value){
			return 0;
		}
		return scores[value];
	},
	dringlichkeit(response){
		return response.data.oOPubf2eWL86CmLwBCBc || "";
	},
	relevanz(response){
		var id = "HCIB78ImyFic8U04iWPp";
		var scores = {
			"absolut-nicht-relevant": "Absolut nicht relevant" ,
			"absolut-relevant": "Absolut relevant",
			"eher-nicht-relevant": "Eher nicht relevant",
			"eher-relevant": "Eher relevant",
		}
		return this.getValue(id, response, scores ) || "";
	},
	umsetzung(response){
		var id = "4Da1epraTLViVJBzEVqH";
		var scores = {
			"umsetzung-nicht-absehbar": 0,
			"umsetzung-definiert-und-beschrieben": 25,
			"umsetzung-entschieden-und-geplant": 50,
			"umsetzung-in-arbeit": 75,
			"umsetzung-erfolgreich-abgeschlossen": 100
		}
		return this.getValue(id, response, scores ) 
	},
	averageScore( scores ){
		if( !scores.length ){
			return 0;
		}
		let score = scores.reduce((a, b) => a + b, 0) / scores.length;
		return score.toFixed(2);
	}

}