<template>
	<v-card color="transparent" flat class="ma-4 mr-5">
		<div style="margin-top: 60px" id="ig-firebaseui-auth-container"></div>
	</v-card>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
@import "../../../node_modules/firebaseui/dist/firebaseui.css";

.firebaseui-container {
	font-family: @ig-font-family !important;
}

.firebaseui-card-actions .firebaseui-form-actions {
	text-align: center;
}
.firebaseui-button,
.mdl-button--raised.mdl-button--colored {
	color: white !important;
	border-radius: 43px !important;
	background-color: @ig-green !important;
}
</style>

<script type="text/javascript">
import firebase from "firebase";
import * as firebaseui from "firebaseui";

// Uses the firebaseui-web lib to render a login form. See documentation at https://github.com/firebase/firebaseui-web
// @group Auth
export default {
	name: "Login",
	methods: {
		// @vuese
		// redirects the user to the /home page following a successful login
		redirect() {
			const self = this;
			if (self.$route.name !== "Ref") {
				self.$router.push("/home");
			}
		},
		// @vuese
		// initiates firebaseui widget
		init() {
			var self = this;
			var uiConfig = {
				callbacks: {
					signInSuccessWithAuthResult: () => {
						self.$store.dispatch("auth/addUserDetails");
						self.redirect();
					},
				},
				signInOptions: [
					{
						provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
					},
				],
			};
			var ui =
				firebaseui.auth.AuthUI.getInstance() ||
				new firebaseui.auth.AuthUI(firebase.auth());
			ui.start("#ig-firebaseui-auth-container", uiConfig);
		},
	},
	mounted() {
		this.init();
	},
};
</script>
