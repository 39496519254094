<template>
	<span>
		<v-btn icon @click="toggle" v-if="hasSidebar"
			><v-icon :color="color">mdi-menu</v-icon></v-btn
		>
		<v-hover v-slot="{ hover }">
			<router-link to="/home" class="unstyled-link">
				<span
					class="ig-logo"
					:class="{ 'secondary--text': hover, [`${color}--text`]: true }"
					>inngo</span
				>
			</router-link>
		</v-hover>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
// renders the inngo logo in the correct colour for the page
// @group Misc
export default {
	name: "InngoLogo",
	computed: {
		hasSidebar() {
			return this.$route.meta.sidebar;
		},
		dark() {
			return this.$route.meta.dark;
		},
		color() {
			return this.dark ? "white" : "primary";
		},
	},
	methods: {
		toggle(){
			// fired when the button to collapse/expand the sidebar is clicked
			this.$emit('toggle')
		}
	}
};
//
</script>
"
