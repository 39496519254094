<template>
	<v-app>
		<v-sheet
			width="100%"
			height="100%"
			:color="isDark ? 'primary' : 'transparent'"
		>
			<top-navigation @toggle="sidebar = !sidebar"> </top-navigation>
			<v-navigation-drawer
				v-if="hasSidebar"
				color="accent"
				class="ig-sidebar"
				app
				v-model="sidebar"
				:width="sidebarWidth"
			>
				<sidebar-navigation @toggle="toggleSidebar"></sidebar-navigation>
			</v-navigation-drawer>
			<v-main>
				<v-container class="ig-main" v-if="canShow">
					<router-view v-if="!loading"></router-view>
					<p v-else class="text-center pt-4">
						<v-progress-circular
							indeterminate
							size="50"
							color="accent"
						></v-progress-circular>
					</p>
				</v-container>
			</v-main>
			<chat v-bind="chatSettings"></chat>
		</v-sheet>
	</v-app>
</template>

<style lang="less">
@import "./assets/css/main";
@import "./assets/css/sidebar.less";
@import "./assets/css/navigation.less";
</style>

<script>
import Vuex from "vuex";

import TopNavigation from "@c/navigation/top-navigation/TopNavigation";
import SidebarNavigation from "@c/navigation/SidebarNavigation";
import Chat from "@c/Chat";

export default {
	name: "App",
	data: () => {
		return {
			sidebar: true,
			sidebarWidth: 490,
			chatSettings: {
				titleClosed: "Ich brauche Hilfe",
				titleOpen: "Hallo",
				introMessage:
					"Guten Tag! Falls Sie Unterstützung benötigen, stehe ich Ihnen jederzeit zur Verfügung.",
				autoResponse:
					"Vielen Dank für Ihre Nachricht. Einen Augenblick bitte.",
				autoNoResponse: "Wie kann ich Ihnen weiter helfen?",
				mainColor: "#789D97",
				intergramId: "-507937725",
			},
		};
	},
	computed: {
		...Vuex.mapState({
			loggedIn: (state) => state.auth.logged_in,
			loading: (state) => state.loading,
			state: (state) => state,
		}),
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isDark() {
			// see router > meta properties documentation
			return this.$route.meta.dark;
		},
		hasSidebar() {
			// see router > meta properties documentation
			return this.$route.meta.sidebar;
		},
		leadOnly() {
			// see router > meta properties documentation
			return this.$route.meta.lead;
		},
		isLead() {
			// see store > assessment > getters documentation
			return this.$store.getters["assessment/isLead"];
		},
		canShow() {
			// uses meta router properties to determine whether to render the page at all
			if (!this.leadOnly) {
				return true;
			}
			if (this.isLead) {
				return true;
			}
			return false;
		},
	},
	watch: {
		// Watches for narrow screens and turns off the sidebar (sidebar still accessible via burger menu)
		isMobile: {
			immediate: true,
			handler(value) {
				if (value) {
					this.sidebar = false;
				}
			},
		},
		// Uses router meta property and the current screen width to determine whether or not the sidebar should be in use
		hasSidebar: {
			immediate: true,
			handler(value) {
				if (value == "expanded" && !this.isMobile) {
					this.sidebar = true;
				}
				if (value == "collapsed") {
					this.sidebar = false;
				}
			},
		},
	},
	components: {
		Chat,
		TopNavigation,
		SidebarNavigation,
	},
	methods: {
		toggleSidebar() {
			// fired when the main sidebar is expanded / collapsed
			this.sidebar = !this.sidebar;
		},
	},
	created(){
		document.title = "Inngo"
	},
	destroyed(){
		this.$store.dispatch("clearAll")
	}
};
</script>
