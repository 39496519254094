<template>
	<div>
		<responsive-navigation :links="links"></responsive-navigation>
		<avatar @click="(e) => openMenu(e)"></avatar>
		<v-menu v-model="menu" :position-x="x" :position-y="y">
			<v-list dense>
				<v-list-item router-link to="/help">
					<v-list-item-icon>
						<v-icon>mdi-help-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ $t("nav.help") }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item v-if="isLead" router-link to="/organisation-profile">
					<v-list-item-icon>
						<v-icon>mdi-hospital-building</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ organisationName }}
						</v-list-item-title>
						<v-list-item-subtitle>
							{{ $t("nav.profile") }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					v-if="isLead"
					router-link
					to="/assessment-config/departments"
				>
					<v-list-item-icon>
						<v-icon>mdi-cog</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ $t("nav.assessment_config") }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="multipleOrganisations" @click="dialog = true">
					<v-list-item-icon>
						<v-icon>mdi-autorenew</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ $t("misc.switch_organisation") }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<logout></logout>
			</v-list>
		</v-menu>
		<v-dialog v-model="dialog" content-class="mw-small-dialog">
			<switch-organisations @close="dialog = false"></switch-organisations>
		</v-dialog>
	</div>
</template>

<style lang="less">
.v-menu .v-list-item {
	cursor: pointer;
}
</style>

<script type="text/javascript">
import Avatar from "@c/auth/Avatar";
import Logout from "@c/auth/Logout";
import ResponsiveNavigation from "@c/navigation/top-navigation/ResponsiveNavigation";
import SwitchOrganisations from "@c/navigation/top-navigation/SwitchOrganisations.vue";

// Renders the navigation content for authenticated users only
// @group Navigation
export default {
	name: "LoggedInTopNavigation",
	components: {
		Logout,
		Avatar,
		ResponsiveNavigation,
		SwitchOrganisations,
	},
	data: () => {
		return {
			menu: false,
			dialog: false,
			x: null,
			y: null,
		};
	},
	computed: {
		canRead() {
			return this.$store.getters["sectionResponses/canRead"];
		},
		organisationName() {
			return this.$store.state.organisation.data.name;
		},
		organisations() {
			return this.$store.state.organisations.data;
		},
		canAccessAssessment() {
			return (
				this.$store.getters["assessment/canAccessAssessment"] &&
				this.$store.getters["departments/canEditOrder"].length
			);
		},
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		canAccessData() {
			return (
				this.$store.getters["assessment/canAccessData"] &&
				this.$store.getters["departments/canReadOrder"].length
			);
		},
		multipleOrganisations() {
			var self = this;
			return Object.keys(self.organisations).length > 1;
		},

		links() {
			const self = this;
			var links = {
				Home: [
					{
						link: "/home",
						name: "Home",
					},
				],
			};
			if (self.canAccessAssessment) {
				links.Erhebung = [
					{
						link: "/assessment",
						name: this.$t("nav.assessment"),
					},
				];
			}
			if (self.canAccessData) {
				links.Ergebnisse = [
					{
						link: "/results",
						name: this.$t("nav.results"),
					},
					{
						link: "/analysis",
						name: this.$t("nav.analysis"),
					},
				];
			}
			return links;
		},
	},
	methods: {
		openMenu(e) {
			e.preventDefault();
			this.menu = false;
			this.x = e.clientX;
			this.y = e.clientY;
			this.$nextTick(() => {
				this.menu = true;
			});
		},
	},
};
//
</script>
"
