<template>
	<span>
		<v-tooltip right>
			<template v-slot:activator="{on}">					
				<v-btn :small="small" class="ma-2" v-on="on" @click="copy" icon>
					<v-icon :small="small" >mdi-content-copy</v-icon>
				</v-btn>
			</template>
			{{$t("buttons.copy")}}
		</v-tooltip>
		<notice v-model="copied" :message="$t('misc.copied')"></notice>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Notice from "@c/notices/Notice"
	// Renders a button to copy a provided strong to the users clipboard
	// @group Buttons
	export default {
		name: "CopyButton", 
		props: {
			// The text to copy
			text: { type: String },
			// Makes the button smaller, defaults to false
			small: { type: Boolean }
		},
		data: () => {
			return {
				copied: false
			}
		},
		components: {
			Notice
		},
		methods: {
			copy(){ 
				this.copied = true;
				navigator.clipboard.writeText(this.text);
			}
		}

	}
// </script>"