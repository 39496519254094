export default {
	firestorePath: "responseData",
	firestoreRefType: "collection",
	moduleName: "responseData",
	statePropName: "data",
	namespaced: true,
	sync: {},
	getters: {
		map(state, _, rootState ){
			var obj = {}
			Object.values(state.data).forEach( dataPoint => {
				var sectionResponse = rootState.sectionResponses.data[dataPoint.sectionResponse];
				if( sectionResponse ){
					var department = sectionResponse.department;
					if( !department ){
						return;
					}
					if( !obj[department] ){
						obj[department] = {}
					}
					var part = dataPoint.part;
					if( !obj[department][part] ){
						obj[department][part] = {}
					}
					var goal = dataPoint.goal;
					obj[department][part][goal] = dataPoint.id;
				}
			})
			return obj;
		}
	},
	mutations: {},
	actions: {
		newResponse({dispatch, rootState, rootGetters }, fields ){
			let newResponse = {
				part: rootState.current.part,
				goal: rootState.current.goal,
				sectionResponse: rootGetters.currentResponse.id,
				user: rootState.auth.data.id,
				comments: "", 
				data: {},
				assessment: rootState.assessment.data.id,
			}
			if( fields ){
				for( var key in fields ){

					newResponse[key] = fields[key];
				}
			}
			return dispatch("insert", newResponse ).then( id => {
				return id;
			})
		},
		fetchBySection({ dispatch, rootGetters, commit }, sectionResponse ) {
            var assessment = rootGetters["assessment/id"];
			dispatch("openDBChannel", {
				clauses: {
					limit: 0,
					where: [
						["assessment", "==", assessment],
						["sectionResponse", "==", sectionResponse],
					],
				},
			}).then( () => {
				commit("LOADING", false, {root: true })
			});
		},
		fetchByAssessment( {dispatch, commit}, id ){
			return dispatch("openDBChannel", {
				clauses: { where: [["assessment", "==", id]], limit: 0 },
			}).then( () => {
				commit("LOADING", false, {root: true })
			});
		}
	},
};
