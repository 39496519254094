<template>
	<v-snackbar :value="value" @input="change" color="primary">{{
		message
	}}</v-snackbar>
</template>

<style lang="less"></style>

<script type="text/javascript">
// displays a snackbar with a message
// @group Notices
export default {
	name: "Notice",
	props: {
		// the triggering value for the snackbar notice
		value: { type: [Boolean, String] },
		// the text for the notice
		message: { type: String },
	},
	methods: {
		change() {
			// emits the input event when the snackbar is hidden
			this.$emit("input");
		},
	},
};
</script>
