export default {
	clearAll({ commit }) {
		
		commit("assessment/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("collaborations/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("delegation/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("departments/RESET_VUEX_EASY_FIRESTORE_STATE");
		// commit("organisation/RESET_VUEX_EASY_FIRESTORE_STATE");
		// commit("organisations/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("CLEAR_FILTER");
		commit("questions/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("responseData/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("sectionParts/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("sections/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("sectionResponses/RESET_VUEX_EASY_FIRESTORE_STATE");
		commit("users/RESET_VUEX_EASY_FIRESTORE_STATE");
	},
	deleteDoc({ dispatch }, { id, storeModule }) {
		return dispatch(`${storeModule}/set`, { id: id, status: "deleted" }).then(
			() => {
				return true;
			}
		);
	},
	getPublished({ dispatch }, storeModule) {
		dispatch(`${storeModule}/fetchAndAdd`, {
			clauses: { where: [["status", "==", "published"]] , limit: 0 },
		});
	},
	startAssessment({ dispatch, rootGetters, commit }, id) {
		dispatch("clearAll");
		commit("LOADING", true);
		if (!id) {
			throw new Error("No ID");
		}

		dispatch("assessment/openDBChannel", {
			pathVariables: { id },
		}).then(() => {
			dispatch("sectionResponses/fetchRespondentResponses");
			dispatch("collaborations/fetchByAssessment");
		});
		var config = rootGetters["organisation/config"];
		dispatch("getPublished", "questions");
		dispatch("goals/fetchAll");
		dispatch("getPublished", "goalGroups");
		if (!config) {
			dispatch("getPublished", "departments");
		} else {
			var departments = Object.keys(config);
			dispatch("departments/fetchByIds", departments);
		}
	},
	startConfig({ dispatch }) {
		dispatch("getPublished", "tags");
		dispatch("getPublished", "departments");
	},
};
