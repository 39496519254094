<template>
	<div class="pl-4 mb-5">
		<v-row class="pa-1 pl-4" style="position: relative">
			<v-switch
				color="primary"
				:label="$t('collaboration.delegate_section')"
				v-model="delegated"
				@change="delegateSection"
			></v-switch>
		</v-row>
		<v-row class="pa-1 pl-4" v-if="delegated && collaboration">
			<v-text-field v-model="link" disabled ref="linkField"> </v-text-field>
			<copy-button :text="link"></copy-button>
		</v-row>
	</div>
</template>

<style lang="less">
.v-btn.right {
	position: absolute;
	right: 10px;
}
</style>

<script type="text/javascript">
import CopyButton from "@c/buttons/CopyButton";

// renders a button which generates a link to share with a delegate respondent
// @group Delegation
export default {
	name: "Delegate",
	props: {
		id: { type: String }, 
		// the id of the department
	},
	data: () => {
		return {
			dialog: false,
			delegated: false,
			collaboration: false,
		};
	},
	components: {
		CopyButton,
	},
	computed: {
		response() {
			return this.$store.getters.responseMap[this.id];
		},
		dark() {
			return this.$route.meta.dark;
		},
		link() {
			if (!this.collaboration) {
				return "";
			}
			var url = window.location.origin;
			return url + "/ref/" + this.collaboration;
		},
	},
	watch: {
		response: {
			deep: true,
			immediate: true,
			handler() {
				if (this.response.status == "delegated") {
					this.delegated = true;
				} else {
					this.delegated = false;
				}
				if (this.response.collaboration) {
					this.collaboration = this.response.collaboration;
				} else {
					this.collaboration = false;
				}
			},
		},
	},
	methods: {
		//@vuese
		// dispatches the delegate event in the store 
		delegateSection() {
			const self = this;
			if (this.delegated) {
				self.$store
					.dispatch("collaborations/delegate", self.response.id)
					.then((id) => {
						self.collaboration = id;
					});
			} else {
				self.$store.dispatch("collaborations/undelegate", self.response.id);
			}
		},
	}
};
//
</script>
"
