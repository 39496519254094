<template>
	<v-list-item class="pl-8" dense>
		<v-list-item-content>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" @click="dialog = 1" block color="primary">
						{{ $t("config.nav.skip") }}
					</v-btn>
				</template>
				<span>{{ $t("config.accept_settings") }}</span>
			</v-tooltip>
		</v-list-item-content>
		<v-dialog v-model="dialog" content-class="mw-medium-dialog">
			<v-card>
				<v-card-title>{{ $t("config.nav.skip") }}</v-card-title>
				<v-card-text>
					<p v-if="countDepartments">
						<span>{{ $t("config.confirm_all") }}</span>
					</p>
					<p v-else>{{ $t("config.accept_empty") }}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="countDepartments == 0"
						color="primary"
						to="/assessment-config/departments"
						@click="dialog = false"
						>{{ $t("config.nav.department_setup") }}</v-btn
					>
					<v-btn v-else color="primary" @click="acceptDefault">{{
						$t("config.accept_settings")
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-list-item>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Pop up dialog offering the user the default configuration
// @group Config
export default {
	name: "AcceptDefaultConfig",
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		config() {
			return this.$store.getters["organisation/config"];
		},
		countDepartments() {
			if (!this.config) {
				return 0;
			}
			return Object.keys(this.config).length;
		},
	},
	methods: {
		// On click, redirect the user to the homepage
		acceptDefault() {
			this.$router.replace("/home");
		},
	},
};
//
</script>
"
