import mwutilities from "@a/utilities.js";
export default {
	firestorePath: "goalGroups",
	firestoreRefType: "collection",
	moduleName: "goalGroups",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	getters: {
		defaultOrder: (state) => {
			var ids = Object.fromEntries( Object.values(state.data).filter( g => g.igid).map((goal_group) => {
                return [
                    goal_group.id,
                    { igid: goal_group.igid },
                ]
            } ) );
			return mwutilities.getPositions(ids, "igid");
		},
	},
	mutations: {},
	actions: {},
};
