export default {
	messages: {
		hello: "Guten Tag",
		welcome: "Herzlich Willkommen bei inngo.",
		lead_welcome:
			"<p>Vervollständigen Sie zunächst Ihr Krankenhausprofil und konfigurieren Sie Ihre Bereiche und Digitalisierungsziele. </p>",
		lead_welcome_title:
			"Dieser Überblick gibt Ihnen einen Blick aus der Vogelperspektive auf Ihre Zusammenarbeit. ",
		delegate_welcome_title:
			"Ihre Umfrage wurde vom Geschäftsbereich IT aktiviert.",
		delegate_welcome:
			"Es werden Ihnen Digitalisierungsziele für Ihren Bereich aufgelistet. Bitte bewerten Sie die Ziele hinsichtlich Relevanz, Umsetzungsgrad und Dringlichkeit.",
		no_sections: "Keine Abschnitte gefunden",
		data_user_welcome:
			"<p>Herzlich Willkommen bei InnGo - Ihrem Digitalisierungsassessment!</p>",
		delegation_over:
			"Das Assessment ist hiermit beendet. Der Geschäftsbereich IT bedankt sich für Ihre Teilnahme!",
	},
	dashboard: {
		feed: "Ihre Neuigkeiten",
		function_overview: "Funktionen im Überblick",
		section: "Bereich",
	},
	results: {
		comments: "Kommentare",
		bar_chart: "Säulendiagramm",
		line_chart: "Liniendiagramm",
		area_chart: "Flächendiagramm",
		hbar_chart: "Balkendiagramm",
		average: "Durchschnitt",
		series: "Serien",
		departments: "Abteilungen",
		all_questions: "Alle Frage",
		goal_groups: "Zieldimensionen",
		your_score: "Meine Organisation",
		all_scores: "Alle Organisationen",
		umsetzung_progress: "Fortschritt Ihrer Digitalisierung",
		handlungsbedarf: {
			title: "Ihre ausgewählten Ziele:",
			intro: "",
		},
	},
	polls: {
		intro:
			"Bitte legen Sie pro zu befragendem Mitarbeiter eine Mini-Umfrage an und leiten Sie den angezeigten Link an ihn/sie weiter. Durch Eintragen eines Titels erkennen Sie später die individuellen Antworten Ihrer Mitarbeiter wieder.",
		overview: "Übersicht Mini-Umfragen",
		launch: "Kolleg*innen befragen",
		button: "Mini-Umfrage",
		new: "Neue Mini-Umfrage",
		nickname: "Titel",
		link: "Link",
		return_message:
			"Sind Sie sicher? Nach dem Speichern können keine weiteren Änderungen vorgenommen werden.",
		none_found: "Keine Mini-Umfragen gefunden",
		thank_you:
			"Vielen Dank für Ihre Teilnahme an dieser Mini-Umfrage im Rahmen der Erfassung der digitalen Reife Ihres Hauses. Ihre Angaben wurden gespeichert und Ihre Mini-Umfrage ist hiermit abgeschlossen. Sie können dieses Fenster schließen.",
		have_responses: "Antworten - Mini-Umfrage",
	},
	survey: {
		access_denied: "Zugang verweigert",
		complete_all_questions_message:
			"Sie können den Fragebogen erst einreichen, wenn alle Fragen beantwortet sind. Sollte dies der Fall sein, drücken Sie bitte noch einmal final auf ‚speichern'.",
		noanswer: "Keine Antwort",
	},
	nav: {
		assessment_config: "Bereichs- und Zielkonfiguration",
		analysis: "Analyse",
		start_assessment: "Erhebung starten",
		results: "Ergebnisse",
		assessment: "Erhebung",
		manage_collaborations: "Zusammenarbeit verwalten",
		profile: "Profil ändern",
		custom_goal: "Neues Ziel",
		add_new_goal: "Neues Ziel hinzufügen",
		assessment_home: "Übersicht der Fachabteilungen",
		pending: "Ausstehend",
		help: "Hilfe",
	},
	buttons: {
		back: "Zurück",
		cancel: "Abbrechen",
		change: "Ändern",
		close: "Schließen",
		config: "Ihre Konfiguration",
		profile: "Ihr Profil",
		complete: "Abschließen",
		confirm: "Bestätigen",
		continue: "Weiter",
		copy: "Kopieren",
		delete: "Löschen",
		download: "Download",
		edit: "Bearbeiten",
		import: "Importieren",
		login: "Einloggen",
		logout: "Ausloggen",
		start: "Starten",
		next: "Weiter",
		return: "Zurück",
		save: "Speichern",
		submit: "Absenden",
	},
	collaboration: {
		are_you_sure_return:
			"Sind Sie sicher, dass Sie die Bewertung an den Geschäftsbereich IT abschicken möchten?",
		activated: "Zusammenarbeitsvorgang aktiviert",
		delegate: "Delegieren",
		delegated: "Delegiert",
		delegate_section: "Abschnitt delegieren",
		delegation: "Delegierung",
		last_login: "Letzter Login",
		dont_delegate_to_yourself:
			"Delegation an Sie selbst oder andere Erhebungsverwalter nicht zulässig",
		please_setup_an_account_message:
			"Bitte richten Sie über das Formular ein Konto ein",
		revoked: "Zusammenarbeitsvorgang wurde widerrufen",
		status: "Status Zusammenarbeit",
		statuses: {
			activated: "Aktiv",
			published: "Inaktiv",
			complete: "Abgeschlossen",
			revoked: "Widerrufen",
			returned: "Retourniert",
			delegated: "Delegiert",
			undefined: " "
		},
	},
	misc: {
		status: "Status",
		saving: "Speichert",
		please_wait: "Bitte Warten",
		fields: "Fields",
		progress: "Fortschritt",
		paste: "Einfügen",
		complete: "Abgeschlossen",
		are_you_still_there: "Sind Sie noch da?",
		logout_warning:
			"Sie sind seit 20 Minuten nicht mehr aktiv gewesen. Aus Sicherheitsgründen werden Sie in {{seconds}} Sekunden automatisch ausgeloggt.",
		switch_organisation: "Organisation wechseln",
		goal_pending: "Prüfung dieses Ziels noch nicht abgeschlossen",
		preview: "Vorschau",
		logged_in_as: "Als {name} einlogget",
		copied: "Kopiert",
		saved: "Gespeichert",
		new_user_info:
			"Danke für die Registrierung. Bitte überprüfen Sie Ihre E-Mails für weitere Informationen.",
		confirm_deletion: "Bitte löschen bestätigen",
		general_error: "Bitte korrigieren Sie die Fehler auf dieser Seite",
		are_you_sure: "Sind Sie sicher?",
		delegation_warning:
			"Sie können einen delegierten Abschnitt nicht bearbeiten",
		completion_warning:
			"Sie können einen abgeschlossenen Abschnitt nicht bearbeiten",
		search: "Suche",
		comment_on_goal: "Ziel Kommentieren",
	},
	tags: {
		search: {
			add_filter: "Filter hinzufügen",
			clear_filter: "Filter zurücksetzen",
			search_by_tags: "Suche nach Tags",
			search_or_add: "Suchen Sie nach Tags oder fügen Sie ein neues hinzu",
			operator_and: "Artikel muss alle Kriterien erfüllen",
			operator_or: "Artikel können beliebige Kriterien erfüllen",
		},
		nav: {
			single: "Tag",
			new: "Neuer Tag",
			plural: "Tags",
		},
		fields: {
			add: "Add Tag",
			description: "Beschreibung",
			name: "Tag",
		},
		are_you_sure:
			"Sind Sie sicher, dass Sie der Datenbank ein neues Tag hinzufügen möchten?",
		tag_exists: "Dieses Tag ist bereits in der Datenbank vorhanden",
	},
	departments: {
		nav: {
			new: "Neue Abteilung",
			plural: "Abteilungen",
			single: "Abteilung",
		},
		fields: {
			section: "Abschnitt",
			name: "Name der Abteilung",
			type: "Art der Abteilung",
		},
	},
	organisations: {
		nav: {
			plural: "Organisationen",
			single: "Organisation",
			new: "Neue Organisation",
		},
		fields: {
			name: "Name",
			address: "Adresse",
			street: "Strasse",
			state: "Bundesland",
			city: "Stadt",
			postcode: "Postleitzahl",
			owner: "Krankenhausbetreiber",
			type: "Art der Organisation",
			beds: "Bettenanzahl",
			university_hospital: "Universitätsklinik",
		},
	},
	questions: {
		config: "Fragenkonfiguration",
		nav: {
			plural: "Fragen",
			single: "Frage",
			new: "Neue Frage",
		},
		fields: {
			scored: "Frage wird bewerted",
			name: "Fragenname",
			name_warning:
				"Der Fragenname kann später nicht mehr bearbeitet werden. Er wird nur als ID verwendet und dem Teilnehmer nicht angezeigt.",
			type: "Question Type",
			title: "Fragetitel",
			helptext: "Hilfetext",
			description: "Beschreibung",
			mouseover: "Mouseover-Text",
			visible_if: "Sichtbar wenn",
		},
		errors: {
			empty: "Alle Antwortoptionen müssen einen Wert haben",
			duplicate_value: "Werte müssen einzigartig sein",
		},
		answer_options: {
			add: "Antwortoption hinzufügen",
			fields: {
				name: "Antwortoptionen",
				text: "Text",
				text_help: "Der Text wird dem Teilnehmer angezeigt",
				value: "Wert",
				value_help:
					"Der Wert wird als Referenz verwendet und mit den Daten exportiert.",
				score: "Score",
			},
		},
	},
	config: {
		goals: {
			confirm_delete: "Möchten Sie dieses Ziel aus Ihrer Umfrage löschen?",
			new_warning:
				"Neu erstellte Ziele müssen von InnGo freigegeben werden, bevor sie der Konfiguration hinzugefügt werden können",
			select_all: "Alle auswählen",
			deselect_all: "Auswahl zurücksetzen",
			hide_goal: "Dieses Ziel überall ausblenden",
			combobox_hint:
				"Wählen Sie aus der Dropdown-Liste oder geben Sie frei ein",
		},
		nav: {
			overview: "Übersicht",
			department_setup: "Bereichskonfiguration",
			goal_config: "Zielkonfiguration",
			goal_setup: "Ziele einrichten",
			goal_groups: "Zieldimension",
			skip: "Voreinstellungen übernehmen",
		},
		intro:
			"Beginnen Sie Ihren ersten Schritt bei inngo und wählen Sie Ihre eigenen Fachabteilungen aus.",
		section_intro: "",
		department_selection_intro:
			"Wählen Sie hier Ihre zu bewertenden Fachabteilungen aus, die in Ihre Gesamtstrategie einbezogen werden sollen",
		can_not_reconfigure:
			"Bereiche, für die bereits eine Erhebung begonnen wurde können nicht weiter konfiguriert werden",
		accept_empty:
			"Um Voreinstellungen für Ziele zu akzeptieren, muss mindestens eine Abteilung aktiv sein",
		accept_settings: "Alle Voreinstellungen übernehmen",
		save: "Einstellungen speichern",
		all_questions: "Alle Frage",
		confirm_all:
			"Sind Sie sicher, dass Sie alle Voreinstellungen übernehmen möchten?",
	},
	users: {
		nav: {
			new: "Neuer Benutzer",
			single: "Benutzer",
			plural: "Benutzer",
		},
		fields: {
			edit_user_assingment: "Benutzerzuordnung bearbeiten",
			lead_respondent: "Hauptverantwortlicher",
			data_user: "Daten-Nutzer",
			name: "Name",
			email: "Email-Addresse",
			organisations: "Organisationen des Benutzers",
			department: "Abschnitt",
		},
	},
	sections: {
		nav: {
			single: "Abschnitt",
			plural: "Abschnitten",
			new: "Neuer Abschnitt",
		},
		parts: {
			add: "Abschnittsteil hinzufügen",
		},
		fields: {
			name: "Abschnittsname",
			save_layout: "Neues Layout speichern",
			add: "Abschnitt hinzufügen",
		},
		goal_groups: {
			title: "Zielgruppen",
			count: "Ziele in dieser Gruppe",
			set_up: "Zieleinstellungen",
			add_more_goals: "Mehr Ziele hinzufügen",
			add_goals: "Ziele hinzufügen",
			add_selected: "Ausgewählte Ziele hinzufügen",
		},
	},
	goals: {
		nav: {
			single: "Ziel",
			plural: "Ziele",
			new: "Neues Ziel",
		},
		fields: {
			name: "Zielname",
			mouseover: "Mouseover-Hilfetext",
			description: "Beschreibung",
		},
	},
	goal_groups: {
		nav: {
			new: "Neue Zielgruppe",
			plural: "Zielgruppen",
			single: "Zielgruppe",
		},
		fields: {
			description: "Beschreibung",
			name: "Zielgruppenname",
		},
	},
};
