export default {
	firestorePath: "collaborations",
	firestoreRefType: "collection",
	moduleName: "collaborations",
	statePropName: "data",
	namespaced: true,
	sync: {
		where: [
			["status", "in", ["published", "activated", "returned", "submitted"]],
		],
		defaultValues: {
			status: "published",
		},
	},
	getters: {
		allPolls(state) {
			return Object.values(state.data).filter((a) => a.type == "poll");
		},
		allSubmittedPolls(state) {
			return Object.values(state.data).filter((a) => {
				return a.type == "poll" && a.status == "submitted";
			});
		},
		currentViewPolls(state, _, rootState, rootGetters) {
			var polls = Object.values(state.data);
			var current = rootState.current;
			return polls.filter((a) => {
				return (
					a.type == "poll",
					a.goal == current.goal &&
						a.goalGroup == rootGetters.currentGoalGroup &&
						a.department == current.department
				);
			});
		},
	},
	mutations: {},
	actions: {
		newCurrentPoll({ dispatch, rootState, rootGetters }) {
			var current = rootState.current;
			var goalGroup = rootGetters.currentGoalGroup;
			var poll = {
				...current,
				type: "poll",
				assessment: rootGetters["assessment/id"],
				goalGroup,
				author: rootGetters["auth/id"],
			};
			dispatch("set", poll);
		},
		fetchByAssessment({ dispatch, rootGetters }) {
			var assessment = rootGetters["assessment/id"];
			if (
				rootGetters["assessment/isLead"] ||
				rootGetters["assessment/isDelegate"]
			) {
				dispatch("openDBChannel", {
					clauses: {
						where: [
							["assessment", "==", assessment], 
							["status", "in", ["published", "activated", "returned", "submitted"]]
						],
					},
				});
			} else {
				return true;
			}
		},
		delegate({ dispatch, rootGetters }, id) {
			var collab = {
				organisation: rootGetters["organisation/id"],
				assessment: rootGetters["assessment/id"],
				type: "delegate",
				sectionResponse: id,
			};
			dispatch("insert", collab).then((res) => {
				dispatch(
					"sectionResponses/set",
					{
						id,
						status: "delegated",
						collaboration: res,
					},
					{ root: true }
				);
			});
		},
		
		undelegate({ rootState, state, dispatch }, id) {
			var response = rootState.sectionResponses.data[id];
			if (!response.collaboration) {
				return;
			}
			var collaboration = state.data[response.collaboration];

			dispatch("set", { id: response.collaboration, status: "revoked" }).then(
				() => {
					dispatch("sectionResponses/revoke", id, {root: true })
					if (collaboration.user) {
						dispatch("sectionResponses/removeUserAccess", { id, user: collaboration.user }, {root: true });
					}
				}
			);
		},
		return({ dispatch }, id) {
			dispatch("sectionResponses/return", id, {root: true} ).then( collab => {
				if( collab ){
					dispatch("set", { id: collab, status: "returned" });
				}
			})
		},
	},
};
