<template>
<v-list-group :value="expanded" dense color="transparent" > 
	<template v-slot:activator>
		<v-list-item dense class="pl-0">
			<v-list-item-title>{{$t("nav.assessment_config")}}</v-list-item-title>
		</v-list-item>
	</template>
	<v-list-item class="pl-8" v-for="(link, i) in links" :key="i" dense :to="link.to">
		<v-list-item-content>
			<v-list-item-title>{{link.text}}</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
	<accept-default-config></accept-default-config>
</v-list-group>
</template>

<style lang="less">
	.v-list-item.no-padding {
		padding-left: 0px;
	}
</style>

<script type="text/javascript">
	import AcceptDefaultConfig from "@c/config/AcceptDefaultConfig"
	// Displays the links for the config navigation when specified in the route meta
	// @group Navigation
	export default {
		name: "ConfigNavigation", 
		components: {
			AcceptDefaultConfig
		},
		computed: {
			links(){
				return [
					{
						text: this.$t("config.nav.department_setup"),
						to:  "/assessment-config/departments"
					},
					{
						text: this.$t("config.nav.goal_config"),
						to:  "/assessment-config/sections"
					}
				]
			},
			expanded(){
				return this.$route.meta.in == "config"
			}
		},

	}
// </script>"