<template>
    <v-avatar :color="dark ? 'green' : 'primary'" size="36" @click="e => $emit('click', e)">
        <span class="small white--text" v-if="initials">{{ initials }}</span>
        <v-icon v-else class="white--text">mdi-account</v-icon>
    </v-avatar>
</template>


<script type="text/javascript">
import Vuex from "vuex";

// Renders an avatar with the authenticated users initials 
// @group Auth
export default {
	name: "Avatar",
	computed: {
		...Vuex.mapState({
			auth: (state) => state.auth.data,
		}),
		dark() {
			return this.$route.meta.dark;
		},
		initials() {
			var name = this.auth.displayName;
			var email = this.auth.email;
			if( !email ){
				return false;
			}
			if (!name) {
				return email.charAt(0).toUpperCase();
			}
			return name
				.split(" ")
				.map((a) => a.charAt(0))
				.join("")
				.toUpperCase();
		},
	},
};
</script>
"
