import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				smallwidth:"120px",
				primary: "#0C134D", 
				secondary: "#F1F1F1",
				igblack: "#000000",
				green: "#789D97", 
				igpurple: "#3C3D72",
				igblue: "#1B247A", 
				iggrey: "#e1e2e3",
				accent: "#D5D7E6 "
			}
		}
	}
});
