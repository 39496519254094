<template>
	<v-row style="margin-top: 70px;">
		<div id="mwChatWrapper"></div>
	</v-row>
</template>
<style>
#mwChatWwrapper {
	position: absolute;
	margin-top: 30px;
}
</style>
<script>
import Vue from "vue";
import Vuex from "vuex";
// Inserts the intergram chat widget script into the page if within the defined hours
// See https://github.com/idoco/intergram for other settings
// @group Misc
export default {
	name: "Chat",
	props: {
		// time to start showing the chat
		start: { type: String, required: false, default: "08:00" },
		// time to end the chat
		end: { type: String, required: false, default: "18:00" },
		// intergram - Closed chat title
		titleClosed: { type: String, default: "Live Chat" },
		// intergram - Opened chat title
		titleOpen: { type: String, default: "Chat" },
		// intergram - First message when the user opens the chat for the first time
		introMessage: {
			type: String,
			default:
				"Hello! How can I help?",
		},
		// intergram - A message that is sent immediately after the user sends its first message
		autoResponse: {
			type: String,
			default: "Thank you! A member of the support team will be with you soon",
		},
		// intergram - A message that is sent one minute after the user sends its first message and no response was received
		autoNoResponse: {
			type: String,
			default: "Sorry it's taking longer than usual to find someone. Thank you for your patience",
		},
		// intergram Can be any css supported color 'red', 'rgb(255,87,34)', etc
		mainColor: { type: String, default: "#789D97" },
		// intergram -  Use the mobile floating button also on large screens
		alwaysUseFloatingButton: { type: Boolean, default: true },
		// the ID retrieved by following the instructions on the intergram github
		intergramId: { type: String, required: true },
	},
	data: () => {
		return {
			date: false,
		};
	},
	computed: {
		...Vuex.mapState({
			user: (state) => state.user,
		}),
		showChat() {
			var time = Vue.moment(this.date).format("HH:mm");
			if (time > this.start && time < this.end) {
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		user: {
			immediate: true,
			handler() {
				if (!window.intergramOnOpen) {
					window.intergramOnOpen = {
						visitorName: "visitor",
					};
				}
				if (this.user) {
					window.intergramOnOpen.visitorName = this.user.email;
				}
			},
		},
		showChat: {
			immediate: true,
			handler() {
				if (this.showChat) {
					this.startChat();
				}
			},
		},
	},
	methods: {
		startChat() {
			var chatScript = document.createElement("script");
			chatScript.setAttribute(
				"src",
				"https://www.intergram.xyz/js/widget.js"
			);
			chatScript.setAttribute("id", "intergram");
			window.intergramId = this.intergramId;
			window.intergramCustomizations = {
				titleClosed: this.titleClosed,
				titleOpen: this.titleOpen,
				introMessage: this.introMessage,
				autoResponse: this.autoResponse,
				autoNoResponse: this.autoNoResponse,
				mainColor: this.mainColor,
				alwaysUseFloatingButton: this.alwaysUseFloatingButton,
			};
			document.body.appendChild(chatScript);
			setTimeout(function() {
				dispatchEvent(new Event("load"));
			}, 200);
		},
	},
	created() {
		this.date = new Date();
	},
};
</script>
