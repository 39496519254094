import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import auth from "./modules/auth.js";
import questions from "./modules/questions";
import assessment from "./modules/assessment";
import collaborations from "./modules/collaborations";
import delegation from "./modules/delegation";
import departments from "./modules/departments";
import goals from "./modules/goals";
import goal_groups from "./modules/goal_groups";
import tags from "./modules/tags";
import users from "./modules/users";
import organisation from "./modules/organisation";
import organisations from "./modules/organisations";
import sections from "./modules/sections";
import sectionResponses from "./modules/sectionResponses";
import sectionParts from "./modules/sectionParts";
import responseData from "./modules/responseData";

const vxf = VuexEasyFirestore(
	[
		assessment,
		auth,
		collaborations,
		delegation,
		departments,
		goals,
		goal_groups,
		organisation,
		organisations,
		questions,
		responseData,
		sections,
		sectionResponses,
		sectionParts,
		tags,
		users,
	],
	{
		logging: false,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils = {
	actions: {
		fetchByIds: ({ dispatch }, ids) => {
			if (!ids) {
				return;
			}
			ids.forEach((id) => {
				dispatch("fetchById", id);
			});
		},
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;
