import { arrayUnion, arrayRemove } from "vuex-easy-firestore";
export default {
	firestorePath: "organisations/{id}",
	firestoreRefType: "doc",
	moduleName: "organisation",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			status: "published",
			departments: [],
			assessmentConfig: null,
			name: "",
		},
	},
	getters: {
		id(state) {
			return state.data.id;
		},
		config(state) {
			if (!state.data.assessmentConfig) {
				return false;
			}
			var config = state.data.assessmentConfig;
			return config.sections || false;
		},
		haveConfig(_, getters) {
			return getters.config !== false;
		},
	},
	actions: {
		start({ dispatch, state }, id) {
			return dispatch("fetchAndAdd", { id }).then(() => {
				dispatch("startAssessment", state.data.assessment, { root: true });
			});
		},
		addSectionsToConfig({ dispatch, rootGetters, state, rootState }, ids) {
			var defaultConfig = rootGetters["defaultConfig"];
			return ids.forEach((id) => {
				var department = rootState.departments.data[id];
				dispatch("patch", {
					assessmentConfig: { sections: { [id]: defaultConfig[id] } },
				});
				var sectionResponse = {
					assessment: state.data.assessment,
					section: department.section,
					department: id,
				};
				dispatch("sectionResponses/insert", sectionResponse, {
					root: true,
				});
			});
		},
		removeGoalFromConfig({ dispatch }, { department, part, goal }) {
			return dispatch("patch", {
				assessmentConfig: {
					sections: { [department]: { [part]: arrayRemove(goal) } },
				},
			});
		},
		addGoalToConfig({ dispatch }, { department, part, goal }) {
			return dispatch("patch", {
				assessmentConfig: {
					sections: { [department]: { [part]: arrayUnion(goal) } },
				},
			});
		},
		saveConfig({ dispatch }, config) {
			return dispatch("patch", { assessmentConfig: { sections: config } });
		},
	},
};
