export default  {
    firestorePath: 'users',
    firestoreRefType: 'collection', 
    moduleName: 'users',
    statePropName: 'data',
    namespaced: true, 
    sync: {
        where: [
            ["status", "in", ["published"] ]
        ],
        defaultValues: {
            status: "published"
        },
    },
    getters: {
        
    },
    mutations: {
    },
    actions: {
    },
}
  
