export default {
	firestorePath: "sectionParts",
	firestoreRefType: "collection",
	moduleName: "sectionParts",
	statePropName: "data",
	namespaced: true,
	getters: {},
	mutations: {},

	actions: {
		fetchAll({ dispatch }) {
			dispatch("fetchAndAdd", {
				clauses: { where: [["status", "==", "published"]] },
			});
		},
		fetchBySection({ dispatch }, section) {
			if (!section) {
				return;
			}
			dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["section", "==", section],
						["status", "==", "published"],
					],
				},
			});
		},
	},
};
